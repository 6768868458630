.form-control {
  background-color: var(--gray-90);
  border: 1px solid var(--gray-90);
  color: var(--white);
  padding: 0.438rem 0.75rem;
  border-radius: 0.625rem;
  height: 3rem;
  font-size: 1rem;

  &::placeholder {
    color: var(--gray-20) !important;
  }

  &:focus {
    color: var(--white);
    border: 1px solid var(--blue-300);
    background-color: var(--gray-90);
    outline: none;
    box-shadow: none;
  }

  &:focus-within {
    box-shadow: none;
  }

  &:disabled,
  &[readonly] {
    background-color: var(--gray-90);
    color: var(--white);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &-dark {
    background-color: var(--darkGray);
    border: 1px solid var(--darkGray);

    &:focus {
      border: 1px solid var(--blue-300);
      background-color: var(--darkGray);
      outline: none;
    }
  }
}

.form-group {
  position: relative;

  & .show-password {
    position: absolute;
    right: 10px;
    top: 38px;
    color: var(--gray-20);
    cursor: pointer;
  }
}

.form-label {
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: var(--gray-40);

  span {
    color: var(--red);
  }
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235A7DA1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  &.error-input {
    border: 1px solid var(--c) !important;

    &:focus {
      border: 1px solid var(--c) !important;
    }
  }
  &[readonly] {
    cursor: not-allowed;
    opacity: 0.7 !important;
    &:hover,
    &:focus,
    &:focus-within {
      border: 1px solid var(--gray-90) !important;
    }
  }
}

.form-check-input {
  border: 1px solid var(--gray-60);
  background-color: transparent;

  &:checked {
    background-color: var(--blue-300);
  }

  &:focus {
    box-shadow: none;
  }
}

.form-check-label {
  color: var(--gray-20);
}
