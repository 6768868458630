@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&family=Teko:wght@600;700&display=swap');
@import 'env';

body {
  font-family: 'Rubik', sans-serif;
  background: var(--darkGray);
  color: var(--white);
  // overflow-x: hidden;
}
@font-face {
  font-family: 'Blaster';
  src: url('../../../public/assets/Fonts/BlasterDemo.woff') format('woff'),
    url('../../../public/assets/Fonts/BlasterDemo.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Tungsten-Narrow';
  src: url('../../../public/assets/Fonts/TungstenNarrow-Bold.woff')
      format('woff'),
    url('../../../public/assets/Fonts/TungstenNarrow-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Tungsten';
  src: url('../../../public/assets/Fonts/Tungsten-Bold.woff2') format('woff2'),
    url('../../../public/assets/Fonts/Tungsten-Bold.woff') format('woff');
  font-weight: bold;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='search'] {
  box-sizing: border-box !important;
}

.theme-card {
  background: var(--gray-90);
  padding: 0.625rem;
  border-radius: 0.5rem;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

.quick-bet-label {
  color: #ffffff;
}

.quick-theme-table {
  thead {
    th {
      padding: 0.3rem;
    }
  }

  tbody {
    tr {
      td {
        padding: 0.3rem;
      }
    }
  }
}

.auth-social-ul {
  gap: 8px;
  min-width: 240px;

  @media screen and (min-width: 768px) {
    min-width: 280px;
  }

  .auth-social-card {
    .auth-social-icon {
      display: flex;
      align-items: center;
      height: 36px;
      border-radius: 0.5rem;
      background: var(--gray-80);
      transition: background-color 0.3s ease 0s;

      svg {
        width: 100%;
        transition: fill 0.3s ease 0s;
      }

      &:hover {
        background: var(--gray-60);

        svg {
          fill: var(--blue-300);
        }
      }
    }
  }
}

.login-social-icons {
  &.auth-social-ul {
    .auth-social-card {
      .auth-social-icon {
        min-height: 36px;
      }
    }
  }
}

.auth-social-listing-wrap,
.login-social-icons,
.third-party-login-section {
  .telegram-login-button {
    border: none;
    outline: none;
  }
}

.third-party-login-section {
  .telegram-login-button {
    background-color: transparent;
  }
}

.header-user-menu {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: var(--gray-90);
    min-width: 16.3125rem;
    padding: 0;
    margin-top: 0.625rem !important;
    border: none;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0 5px 8px -4px,
      rgba(0, 0, 0, 0.18) 0px 0px 20px 0px,
      rgba(0, 0, 0, 0.35) 0px 40px 34px -16px;

    @media screen and (min-width: 768.98px) and (max-height: 662.98px) {
      height: calc(100vh - 145px);
      overflow-y: scroll;
    }

    li {
      margin: 0.313rem 0;
      padding: 0 0.625rem;
    }

    .dropdown-item {
      color: var(--gray-20);
      font-size: 0.875rem;
      padding: 0.75rem;
      font-weight: 500;
      border-radius: 0.625rem;
      background-color: transparent;
      border: none;
      line-height: 1rem;
      // MOBILE MENU ISSUE SMALL SCREEN
      @media screen and (max-height: 660px) and (max-width: 767.98px) {
        padding: 0.5rem !important;
      }
      svg {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
      }

      &.active,
      &:active {
        background-color: var(--gray-60);
        color: var(--white);

        img {
          filter: brightness(0) saturate(100%) invert(21%) sepia(74%)
            saturate(2846%) hue-rotate(177deg) brightness(94%) contrast(99%);
        }
      }

      &:hover {
        background: var(--gray-80);
        color: var(--white);
      }

      // &.active{
      //   color: var(--blue-300);
      //   text-shadow: var(--blue-300) 0px 0px 8px;
      //   & svg{
      //     filter: drop-shadow(var(--blue-300) 0px 0px 6px) brightness(5);
      //   }
      // }
    }

    .user-name {
      padding: 0.75rem 1.25rem;
      background: var(--gray-80);
      margin-bottom: 10px;

      h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: var(--white);
      }
    }
  }
  // @media screen and (max-height: 430px) {
  //   ul.dropdown-menu.show {
  //     max-height: 208px;
  //     overflow: auto;
  //   }
  // }
}

.account-btn-wrap {
  .account-btn {
    padding: 0.25rem 0.625rem;
    background: var(--gray-85) !important;
    border-color: var(--gray-85) !important;
    min-height: 44px;

    @media screen and (max-width: 767.98px) {
      justify-content: center;
    }

    @media screen and (max-width: 575.98px) {
      padding: 0.25rem 0.5rem;
      min-height: 40px;
      height: 40px;
      width: 40px;
    }

    img {
      width: 34px;
      margin-right: 0;

      @media screen and (max-width: 575.98px) {
        width: 32px;
        height: 32px;
      }
    }

    p {
      font-size: 0.75rem;
      text-transform: capitalize;
      text-overflow: ellipsis;
      max-width: 100px;
      overflow: hidden;
    }

    &:hover {
      .dropdown-icon {
        background-color: var(--gray-80) !important;
      }
    }
  }

  .account-btn.show {
    background: var(--gray-85);
  }

  &::after {
    display: none;
  }

  .dropdown-icon {
    background: var(--gray-80);
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-20);
    border-radius: 0.313rem;

    & i {
      font-size: 0.75rem;
    }
  }
}

.content-wrapper {
  margin: 4.75rem auto 0;
  width: 100%;
  padding: 1.563rem 0.625rem 0.625rem;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 767.98px) {
    padding: 1.5rem 0.938rem 0;
  }
  @media screen and (max-width: 577px) {
    margin: 4rem auto 0;
  }
  @media screen and (max-width: 577px) {
    margin: 4rem auto 0;
  }

  &.custom-spacer {
    max-width: 1165px;
    padding: 2rem 1.6875rem 0;

    @media screen and (max-width: 767.98px) {
      padding: 1.5rem 0.938rem 0;
    }
  }

  &.chat-parent {
    padding-left: 240px;
  }

  &.sportsbookwrap {
    padding: 0;
  }
}

.crypto-main-wrapper {
  padding: 1.25rem 1.375rem 0.625rem 1.125rem;

  @media screen and (max-width: 575px) {
    padding: 0.75rem;
  }
}

// .page-container {
#urm-chat-container {
  @media screen and (max-width: 1399.98px) {
    padding-bottom: 4.25rem;
  }

  &.chat-parent {
    // padding-right: 248px;
    margin-right: 248px;

    @media screen and (max-width: 1024.98px) {
      padding: 0;
    }

    .global-casino-section {
      .theme-card-wrap {
        width: auto;
        min-width: auto;
        // max-width: auto;
        height: auto;
        min-height: auto;
        // max-height: auto;
      }
    }

    .clutch-section {
      .theme-card-wrap {
        .theme-card {
          padding: 0.625rem;

          .clutch-card-content {
            h4 {
              font-size: 0.75rem;
              white-space: nowrap;

              a {
                font-size: 0.75rem;
              }
            }

            .clutch-card-content-left {
              img {
                margin-right: 0.313rem !important;
              }
            }

            .btn-secondary {
              min-width: auto;
              padding: 0.25rem 0.5rem;
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .poker-section {
      .theme-card-wrap {
        .theme-card {
          h4 {
            font-size: 1rem;
          }
        }
      }
    }

    // .crypto-won-section{
    //   h3{
    //     font-size: 1rem;
    //   }
    //   .payment-options{
    //     img{
    //       width: 2.5rem;
    //     }
    //   }
    // }
  }

  &.chat-active {
    padding-right: 0;

    @media screen and (min-width: 1400px) {
      & {
        // padding-right: 352px;
        margin-right: 352px;
      }
    }

    & .profile-total-card {
      @media screen and (max-width: 1460px) {
        margin-bottom: 1rem;
      }
    }
  }
}

.global-casino-section {
  .game-slider-header {
    .game-category {
      @media screen and (max-width: 575px) {
        order: 1;
      }
    }

    .recently-played {
      &.heading-right-listing {
        li {
          @media screen and (max-width: 575px) {
            margin-left: 0 !important;
          }
        }

        @media screen and (max-width: 575px) {
          margin-top: 8px;
        }
      }

      @media screen and (max-width: 575px) {
        order: 3;
        flex-grow: 1;
        width: 100%;
      }
    }

    .custom-arrow {
      @media screen and (max-width: 575px) {
        order: 2;
        top: 32px !important;
      }
    }

    .heading-right-listing {
      @media screen and (max-width: 767px) {
        padding-bottom: 0 !important;
      }

      @media screen and (max-width: 575px) {
        padding-bottom: 0 !important;
        margin-right: 4rem;
      }
    }

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
    }
  }
}

.theme-dropdown-menu {
  background-color: var(--gray-90);
  min-width: 13.438rem;
  padding: 0;
  margin-top: 0.625rem !important;
  border: 1px solid var(--gray-80);
  border-radius: 0.625rem;
  overflow: hidden;

  li {
    margin: 0.313rem 0.625rem;
    border-radius: 0.625rem;

    a {
      color: var(--gray-20);
      font-size: 0.875rem;
      padding: 0.625rem;
      font-weight: 500;
      border-radius: 0.625rem;

      &:hover {
        background-color: transparent;
        color: var(--white);
      }
    }

    &:hover {
      background-color: var(--gray-90);
    }
  }
}

.custom-radio-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: var(--darkGray);
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--darkGray);
    }
  }

  &:hover input ~ .custom-radio-btn {
    background-color: var(--darkGray);
  }

  & input:checked ~ .custom-radio-btn {
    background-color: var(--blue-300);

    &:after {
      display: block;
    }
  }
}

.profile-tabs {
  margin-right: 1.25rem;

  .nav {
    flex-direction: column;
  }

  @media screen and (max-width: 1199.98px) {
    width: 100% !important;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;

    .nav {
      flex-direction: row;
    }
  }

  .nav-link {
    background: var(--gray-90);
    margin: 0.25rem 0;
    min-width: 184px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-20);
    font-weight: 500;
    border-radius: 0.625rem;
    border: 2px solid transparent;
    font-size: 0.875rem;

    @media screen and (max-width: 1199.98px) {
      min-width: auto;
      margin: 0.25rem;
    }

    &:first-child {
      @media screen and (max-width: 1199.98px) {
        margin-left: 0;
      }
    }

    &:last-child {
      @media screen and (max-width: 1199.98px) {
        margin-right: 0;
      }
    }

    &.active {
      background: var(--gray-60);
      border-color: transparent;
      color: var(--white);
    }

    &:hover {
      background: var(--gray-80);
      color: var(--white);
    }

    svg {
      margin-right: 0.625rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.casino-search {
  padding-bottom: 1rem;

  .casino-search-content {
    position: relative;

    input {
      background-color: var(--darkGray);
      border-radius: 0.625rem;
      height: 45px;
      border: 1px solid var(--gray-80);
      padding-left: 3rem;
    }

    .search-icon {
      position: absolute;
      left: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .btn-close {
    position: absolute;
    right: 0.625rem;
    top: 0.5rem;
    opacity: 1;
  }
}

.modal {
  backdrop-filter: blur(2px) brightness(0.5);
  -webkit-backdrop-filter: blur(2px) brightness(0.5);
  z-index: 1199;

  &:first-of-type {
    backdrop-filter: none;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      margin: 1.75rem auto;
    }
  }
}

.global-casino-section {
  padding-bottom: 1.5rem;

  .theme-card-wrap {
    .theme-card {
      padding: 0;
      background: var(--gray-90);
      transition: transform 0.15s ease-out 0s;

      .casino-img-wrap {
        width: 100%;
        aspect-ratio: 440/600;
        position: relative;
        border-radius: 0.5rem;
        cursor: pointer;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .card-overlay {
          position: absolute;
          background: var(--overlayBg);
          height: 100%;
          width: 100%;
          display: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: var(--white);
          animation: 300ms ease-in 0s 1 normal none running overlayEffect;

          h4 {
            font-size: 0.875rem;
            margin: 0 0 0.313rem;
            text-transform: uppercase;
            font-family: 'Rubik', sans-serif;
            font-weight: 700;
            text-align: center;

            @media screen and (max-width: 1199.98px) {
              font-size: 0.675rem;
              text-align: center;
            }

            @media screen and (max-width: 1199.98px) {
              font-size: 0.875rem;
            }
          }

          p {
            margin: 0 0 0.313rem;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
      }

      &:hover {
        transform: translateY(-8px);

        & .card-overlay {
          display: flex;
          transition: all 1s ease-in-out 1s;
        }
      }
    }
  }
}

@keyframes overlayEffect {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes accordianEffect {
  0% {
    transform: scale(1.02);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

::-webkit-input-placeholder {
  color: var(--gray-20) !important;
  font-size: 0.875rem;
}

::-moz-placeholder {
  color: var(--gray-20) !important;
  font-size: 0.875rem;
}

:-ms-input-placeholder {
  color: var(--gray-20) !important;
  font-size: 0.875rem;
}

:-moz-placeholder {
  color: var(--gray-20) !important;
  font-size: 0.875rem;
}

.rc-tooltip-inner {
  background-color: var(--gray-80) !important;
  border-color: var(--gray-80) !important;
  color: var(--white) !important;
  border-radius: 0.25rem !important;
  font-size: 0.75rem;
  opacity: 1;
  font-weight: 400;
  padding: 4px 8px !important;
  min-height: 28px !important;

  p {
    margin: 0 !important;
  }
}

.rc-tooltip {
  width: auto !important;
}

.rc-tooltip-placement-left {
  .rc-tooltip-arrow {
    border-left-color: var(--gray-80) !important;
    right: -4px !important;
  }
}

.rc-tooltip-placement-topLeft {
  .rc-tooltip-arrow {
    border-top-color: var(--gray-80) !important;
  }
}

.rc-tooltip-placement-top {
  .rc-tooltip-arrow {
    border-top-color: var(--gray-80) !important;
  }
}

.rc-tooltip-placement-right {
  .rc-tooltip-arrow {
    border-right-color: var(--gray-80) !important;
    left: -5px !important;
  }
}

.rc-tooltip-placement-bottom {
  .rc-tooltip-arrow {
    border-bottom-color: var(--gray-80) !important;
    margin: 0 !important;
  }
}

.animated-table {
  position: relative;

  .table-head {
    position: relative;
    z-index: 2;
  }

  .table-animation:nth-of-type(2n + 1) {
    animation: 500ms ease-out 0s 1 normal none running tAnimation;
  }

  .table-animation:nth-of-type(2n) {
    animation: 500ms ease-out 0s 1 normal none running hideAnimation;
  }

  &-gradient {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 218px;
      content: '';
      background: var(--table-fade);
      width: 100%;

      @media screen and (max-width: 1024.98px) {
        display: none;
      }
    }
  }
}

@keyframes hideAnimation {
  0% {
    transform: translateY(-100%);
  }
}

@keyframes tAnimation {
  0% {
    transform: translateY(-100%);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#bt-inner-page {
  position: relative;
  z-index: 1;
}

.theme-divider {
  background-color: var(--gray-40);
  margin: 0.313rem auto;
  width: 90%;
}

.live-wins {
  padding-bottom: 1.5rem;

  .live-wins-empty {
    display: inline-flex;
    align-items: center;
    background-color: var(--gray-85);
    margin: 0 auto;
    border-radius: 8px;
    padding: 0.5rem 1.5rem;
    color: var(--gray-20);
    font-weight: 500;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  .live-wins-heading {
    font-size: 1rem;
    font-weight: 700;
  }

  .live-card {
    text-align: center;
    margin: 0 0.313rem;
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 0 0 75px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .live-img {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      cursor: pointer;
      // aspect-ratio: 440 / 552;

      & img {
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        width: 80px;
        height: auto;
        cursor: pointer;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    p {
      font-size: 0.625rem;
      font-weight: 500;
      line-height: 1rem;
      color: var(--white);
      margin: 0.313rem 0 0;
      // max-width: 75px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span {
      color: var(--live-green);
      font-size: 0.725rem;
      font-weight: 400;
      line-height: 1.125rem;
      display: block;
      // max-width: 75px;
    }
  }

  .live-card-wrap {
    // overflow-x: overlay;
    // white-space: nowrap;
    margin-left: 6px;

    @media screen and (max-width: 575.98px) {
      & {
        justify-content: space-between;

        .live-card:nth-child(n + 5) {
          display: none;
        }
      }
    }
  }

  .swiper-navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 1.5rem;
    position: relative;
    top: 0;
    margin-bottom: 12px;

    &-arrow {
      border-radius: 0.313rem;
      background-color: rgba(40, 60, 81, 0.7);
      border: 1px solid rgba(40, 60, 81, 0.7);
      padding: 0.313rem 0.625rem;
      font-size: 0;
      width: 1.5rem;
      height: 1.2rem;
      display: block;
      margin-left: 0.4rem;
      position: relative;
      inset: unset;
      transform: translate(0, 0);
      transition: background-color 0.3s ease;

      &::before {
        position: absolute;
        left: 0.5rem;
        top: 0.3rem;
        content: '';
        font-size: 0;
        width: 0.313rem;
        height: 0.625rem;
        opacity: 1;
      }

      &:first-child {
        margin-left: 0;
      }

      &.swiper-button-lock {
        display: none;
      }

      &:not(.swiper-button-disabled):hover {
        background: var(--gray-60);
        border-color: var(--gray-60);
        cursor: pointer;
      }
    }

    &-prev-step {
      &:before {
        background: url('#{$s3Path}/assets/Icons/svg/slider-left-arrow.svg')
          no-repeat;
        background-size: 0.313rem;
      }

      &.swiper-button-disabled {
        &:before {
          background: url('#{$s3Path}/assets/Icons/svg/slider-left-arrow-grey.svg')
            no-repeat;
          background-size: 0.313rem;
        }
      }
    }

    &-next-step {
      &:before {
        background: url('#{$s3Path}/assets/Icons/svg/slider-right-arrow.svg')
          no-repeat;
        background-size: 0.313rem;
      }

      &.swiper-button-disabled {
        &:before {
          background: url('#{$s3Path}/assets/Icons/svg/slider-right-arrow-grey.svg')
            no-repeat;
          background-size: 0.313rem;
        }
      }
    }
  }
  .swiper {
    width: 100%;
    margin: 0;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: 0.75rem 0 0;
    }
  }

  .swiper-slide {
    width: 7.7%;
    min-width: 90px;
    padding: 0 5px;
  }

  .providers-swiper {
    .swiper {
      margin: 0 -0.375rem;
    }

    .swiper-wrapper {
      padding: 18px 0 0;

      @media screen and (max-width: 767.98px) {
        padding: 0.75rem 0 0;
      }
    }

    .swiper-slide {
      width: 16.666%;
      min-width: 160px;
      padding: 0 0.375rem;
    }
  }

  .nft-swiper {
    .swiper {
      margin: 0 -0.375rem;
    }

    .swiper-wrapper {
      padding: 1.1875rem 0 0;

      @media screen and (max-width: 767.98px) {
        padding: 0.75rem 0 0;
      }
    }

    .swiper-slide {
      width: 16.666%;
      min-width: 160px;
      padding: 0 0.375rem;
    }
  }

  .trading-swiper {
    .swiper {
      margin: 0 -0.375rem;
    }

    .swiper-wrapper {
      padding: 1.1875rem 0 0;

      @media screen and (max-width: 767.98px) {
        padding: 0.75rem 0 0;
      }
    }

    .swiper-slide {
      width: 33.333%;
      min-width: 374px;
      padding: 0 0.375rem;
    }
  }
}

.inner-heading {
  display: flex;
  align-items: center;

  // margin-bottom: 0.625rem;
  // min-height: 1.5rem;
  @media screen and (max-width: 767.98px) {
    margin-bottom: 0.625rem;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: var(--white);
    margin: 0;

    @media screen and (max-width: 500.98px) {
      max-width: 125px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-size: 0.75rem;
      overflow-y: hidden;
    }
  }

  img,
  svg {
    margin-right: 0.625rem;
    filter: brightness(10);
    height: 1.25rem;
    width: 1.25rem;
  }

  .week-wrap {
    a {
      background: var(--gray-90);
      border-radius: 0.438rem;
      color: var(--gray-20);
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.313rem;
      min-height: 32px;
      min-width: 32px;
      padding-top: 2px;
      transition: background-color 0.3s ease 0s, color 0.3s ease 0s;

      &.linkActive,
      &:hover {
        color: var(--white);
        background-color: var(--gray-60);
      }
    }
  }

  .heading-link {
    color: var(--blue-300);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-left: 1rem;
    text-decoration: underline;
    transition: color 0.3s ease 0s;

    &:hover {
      color: var(--blue-400);
    }

    &:before {
      display: none;
    }
  }
}

.provider-section {
  padding-bottom: 1.875rem;

  .theme-card {
    height: 100%;
    padding: 1.5rem 1.25rem 1rem;
    background: var(--gray-90);
    text-align: center;
    min-height: 132px;
    border-radius: 0.625rem;
    border: none;
    cursor: pointer;
    transition: transform 150ms ease-out 0s;

    &:hover {
      transform: translateY(-0.5rem);
    }

    @media screen and (max-width: 767.98px) {
      padding: 0.5rem;
    }

    .provider-logo {
      height: 60px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 767.98px) {
        margin: 0 auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .provider-badge-wrap {
      // background: var(----blue-300);
      border-radius: 1.063rem;
      padding: 0;
      color: var(--gray-20);
      text-align: center;
      font-size: 0.75rem;
      display: inline-block;
      // margin-top: 1rem;
      font-weight: 500;
    }
  }

  @media screen and (min-width: 768px) {
    .col-lg-2 {
      width: 20%;
    }
  }
}

.table-section {
  .inner-heading {
    margin-bottom: 0.75rem;
  }

  .heading-right-listing {
    margin-right: 0;

    a {
      color: var(--gray-20);
      background: var(--gray-85);
      padding: 0.313rem 0.625rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      font-weight: 500;
      transition: background-color 0.3s ease 0s, color 0.3s ease 0s;

      &:hover {
        background: var(--gray-60) !important;
        color: var(--white) !important;
      }
    }

    ul {
      @media screen and (max-width: 767.98px) {
        flex-wrap: nowrap;
      }

      li {
        margin: 0 2px;

        &.active {
          a {
            background: var(--gray-60);
            color: var(--white);
          }
        }

        &:hover {
          a {
            background: var(--gray-85);
          }
        }
      }
    }

    .nav-tabs {
      border: none;
      padding: 0.313rem 0;
    }
  }

  .text-success {
    color: var(--live-green) !important;
  }

  .user-details {
    .user-image {
      height: 2rem;
      width: 1.5rem;
      border-radius: 0.5rem;
      background: var(--gray-80);

      & img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.heading-right-listing {
  margin-right: 0;
  margin-left: auto;

  @media screen and (max-width: 767.98px) {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 0.8rem;
  }

  a {
    color: var(--gray-40);
    padding: 0.313rem 0.625rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
  }

  li {
    margin: 0 0.313rem;

    &.active {
      a {
        background: var(--gray-85);
        color: var(--white);
      }
    }

    &:hover {
      a {
        background: var(--gray-85);
      }
    }
  }

  .nav-tabs {
    border: none;
  }
}

.provider-list {
  background: var(--gray-90);
  border-radius: 0.625rem;
  padding-top: 0;
  position: relative;
  min-width: 320px;
  margin-top: 20px !important;
  padding-bottom: 0;
  overflow: hidden;

  @-moz-document url-prefix() {
    scrollbar-color: var(--gray-80) transparent;
    scrollbar-width: thin;
  }

  @media screen and (max-width: 767.98px) {
    min-width: 100%;
  }

  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 6px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-80);
    border-radius: 10px;
    border: 6px solid var(--gray-90);
  }

  .provider-list-right {
    justify-content: flex-end;
    width: 50%;
  }

  &:before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--gray-90);
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 50%;
    transform: translate(-50%);
  }

  .dropdown-item {
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    border-radius: 0.375rem;
    color: var(--gray-20);
    font-weight: 500;
    height: 40px;
    transition: background-color 0.3s, color 0.3s;

    &:hover,
    &:active,
    &:focus {
      background-color: var(--gray-85) !important;
      color: var(--white);

      & span {
        color: var(--white);
      }
    }
  }

  .casino-search {
    padding: 0.625rem;
    background: var(--gray-90);
    border-bottom: 1px solid var(--gray-80);

    input {
      padding-left: 2rem;
      font-size: 0.874rem;
      height: 40px !important;
    }

    .search-icon {
      width: 1.1rem;
      height: 1.1rem;
    }
  }

  .provider-list-wrap {
    padding: 0.313rem;
    position: relative;
    max-height: 340px;
    overflow-x: hidden;
    overflow-y: auto;

    @-moz-document url-prefix() {
      scrollbar-color: var(--gray-80) transparent;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: 6px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray-80);
      border-radius: 10px;
      border: 6px solid var(--gray-90);
    }
  }

  .wallet-setting-btn-wrap {
    background: var(--gray-85);
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;

    & a {
      color: var(--gray-40);
      font-size: 0.875rem;
      font-weight: 500;

      &:hover {
        color: var(--blue-300);

        svg path {
          fill: var(--blue-300);
        }
      }

      & svg {
        margin-right: 0.313rem;
      }
    }
  }

  &.search-provider-list {
    margin-top: 0 !important;
    min-width: auto;

    .dropdown-item {
      &.active {
        background: var(--darkGray);
        color: var(--white);
      }
    }

    &:before {
      display: none;
    }
  }
}

.search-filter-right {
  row-gap: 0.5rem;

  @media screen and (max-width: 500px) {
    column-gap: 4px;
    .btn {
      padding: 0 !important;
    }
  }

  .btn-primary {
    font-size: 0.875rem;
    min-height: 2.75rem;
    line-height: 1.688rem;

    span {
      color: var(--blue-300);
    }
  }

  @media screen and (max-width: 767.98px) {
    .btn-group {
      //   flex: 1;
      width: calc((100% - 4px) / 2) !important;
      flex: unset !important;
    }

    & .btn {
      width: 100%;
    }
  }
  @media screen and (max-width: 470px) {
    .me-1 {
      margin-right: 0 !important;
    }
    .btn-group {
      flex: unset !important;
      width: 100% !important;
    }

    & .btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .btn-group {
      flex: 0 0 100%;
      max-width: 100%;
    }

    & .btn {
      width: 100%;
    }
  }

  .header-wallet-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
    background: var(--secondaryRadialBtn);
    border-radius: 0 6px 6px 0;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      background: var(--blue-400);
    }

    @media screen and (max-width: 575.98px) {
      width: 2.5rem;
      flex: 0 0 2.5rem;
      height: 2.5rem;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.inner-loader {
  svg {
    height: 68px;
    width: 68px;
  }
}

.color-red {
  color: var(--red) !important;
}

.view-all-page-wrap {
  .view-all-card-wrap {
    display: grid;
    margin-top: 1.125rem;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    gap: 12px;

    .global-casino-section {
      .theme-card-wrap {
        max-width: 100%;
        max-height: 100%;
        padding: 0 0.25rem;
      }
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// .page-container {
#urm-chat-container {
  margin-left: 52px;
  transition: all 200ms ease-in-out;
  max-height: calc(100vh);
  min-height: calc(100vh);
  overflow: auto;
  position: relative;

  @media screen and (min-width: 1400px) {
    & {
      margin-left: 250px;

      &.sidebar-close {
        margin-left: 52px;
      }
    }
  }

  @media screen and (max-width: 1024.98px) {
    & {
      margin-left: 0;
    }
  }
}

.language-btn {
  &.single-language {
    span {
      background-color: var(--gray-80) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      &:hover {
        background-color: var(--gray-80) !important;
        border-color: var(--gray-80) !important;
      }
    }
  }
  .dropdown-menu {
    background: var(--gray-80);
    box-shadow: 0 0 16px 0 #0f1923f5;
    padding: 0.5rem !important;

    .dropdown-item {
      color: var(--white);
      font-size: 0.875rem;
      font-weight: 400;

      &:hover {
        background: var(--gray-60);
      }
    }
  }

  .btn-primary {
    background: var(--gray-80);
    padding: 0 0.313rem;
    font-size: 0.75rem;
    min-height: 1.688rem;
    color: var(--gray-20);
    font-weight: 300;
  }
}

.chat-notification-icon {
  height: 0.5rem;
  width: 0.5rem;
  background: var(--live-green);
  border-radius: 100%;
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.border-muted {
  border-color: var(--minePlachholder) !important;
}

.overflowHidden {
  overflow: hidden;
}

.btn-close {
  box-shadow: none !important;
  z-index: 1;
  position: absolute;
  right: 1.875rem;
  top: 1rem;
  background: none;
  background-size: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767.98px) {
    right: 0.625rem;
    top: 0.625rem;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.custom-switch-btn-wrap {
  .input-label {
    margin-left: 0.625rem;

    h6 {
      font-size: 1rem;
    }

    p {
      font-size: 0.875rem;
      color: var(--gray-40);
    }
  }

  .custom-switch-btn {
    input[type='checkbox'] {
      &.switch-btn {
        font-size: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 3.5em;
        height: 1.5em;
        background: var(--gray-80);
        border-radius: 3em;
        position: relative;
        cursor: pointer;
        outline: none;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;

        &:checked {
          background: var(--live-green);

          &:after {
            left: calc(100% - 1.5em);
          }
        }

        &:after {
          position: absolute;
          content: '';
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          background: #fff;
          -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
          transform: scale(0.7);
          left: 0;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.form-group.required .control-label:after {
  content: '*';
  color: var(--red);
}

input[type='checkbox'].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

// input:checked + label:after {
//   left: calc(100% - 5px);
//   transform: translateX(-100%);
// }

label:active:after {
  width: 130px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

.modal-title {
  color: var(--white);
}

.auth-social-heading {
  margin: 0.3rem 0;
  h6 {
    color: var(--gray-40);
    text-align: center;
    position: relative;
    margin: 0;

    &::before {
      position: absolute;
      content: '';
      top: 0.625rem;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--gray-80);
    }

    span {
      background: var(--gray-90);
      padding: 0 1rem;
      position: relative;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }
}

.lh-20 {
  line-height: 1.25rem !important;
}

.crypto-table-wrapper {
  padding-top: 18px;
}

.crypto-table {
  .theme-table {
    border-spacing: 0 0.25rem;

    tr {
      td {
        background: var(--gray-90);
        padding: 0.25rem 0.938rem;

        button {
          line-height: 1rem;
          font-size: 0.75rem;
          max-width: 7rem;
          min-height: 2rem;
        }
      }
    }
  }
}

.text-success {
  color: var(--live-green) !important;
}

.custom-switch-wrap {
  label {
    color: var(--gray-40);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75rem;
  }

  .custom-switch {
    position: relative;
    background: var(--gray-80);
    width: 40px;
    height: 24px;
    border-radius: 100vh;
    transition: background 0.4s ease;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--gray-40);
      transform: translateY(-50%);
      transition: transform 0.4s ease;
    }

    &:has(input[type='checkbox']:checked) {
      background: var(--live-green);

      &::before {
        background: var(--gray-90);
        transform: translate(16px, -50%);
      }
    }

    input[type='checkbox'] {
      opacity: 0;
      position: absolute;
      inset: 0;
      cursor: pointer;
    }
  }
}
