.main-loader{
  position: fixed;
  z-index: 999;
  background: var(--loaderBg);
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width:25% !important;

    @media screen and (max-width: 767.98px){
      width: 80% !important;
    }
  }
}
