@import 'react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: var(--gray-85);
  --toastify-color-info: var(--blue-300);
  --toastify-color-success: var(--sport-green);
  --toastify-color-warning: var(--orange);
  --toastify-color-error: var(--red);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 340px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 56px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  // used to control the opacity of the progress trail
  --toastify-color-progress-bgo: .2;
}

.Toastify {
  $root: &;

  &__toast {
    align-items: center;
    padding: 8px 11px 8px 8px;

    @media screen and (min-width: 480px) {
      border-radius: 8px;
    }

    &--info {
      #{$root}__toast-icon {

        &:before {
          background: radial-gradient(50% 50% at 50% 50%, rgba(0, 164, 250, 0.12) 0%, rgba(0, 164, 250, 0) 100%);
        }
      }
    }

    &--error {
      #{$root}__toast-icon {

        &:before {
          background: radial-gradient(50% 50% at 50% 50%, rgba(255, 73, 73, 0.12) 0%, rgba(255, 73, 73, 0) 100%);
        }
      }
    }

    &--success {
      #{$root}__toast-icon {

        &:before {
          background: radial-gradient(50% 50% at 50% 50%, rgba(162, 215, 114, 0.12) 0%, rgba(162, 215, 114, 0) 100%);
        }
      }
    }

    &--warning {
      #{$root}__toast-icon {

        &:before {
          background: radial-gradient(50% 50% at 50% 50%, rgba(255, 176, 24, 0.12) 0%, rgba(255, 176, 24, 0) 100%);
        }
      }
    }

    &:hover {
      #{$root}__close-button {
        background: var(--gray-80);
      }
    }
  }

  &__toast-icon {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 212px;
      height: 212px;
    }

  }

  &__progress-bar {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: red;
      filter: blur(4px);
    };


    &--error {
      &:before {
        background: var(--toastify-color-error);
      }
    }

    &--success {
      &:before {
        background: var(--toastify-color-success);
      }
    }

    &--info {
      &:before {
        background: var(--toastify-color-info);
      }
    }

    &--warning {
      &:before {
        background: var(--toastify-color-warning);
      }
    }
  }


  &__toast-icon {
    width: 32px;
    height: 32px;
  }

  &__toast-body {
    padding: 4px 6px 6px 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.4px;
    text-align: left;
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    color: var(--white);
    opacity: 1;
    background: var(--gray-85);
    width: 32px;
    height: 32px;
    border-radius: 50%;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}
