.games-not-found{
  margin-top: 3rem;
 span{
  background-color: var(--gray-90);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  color: var(--gray-40);
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
 }
}
