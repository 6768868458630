*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background: var(--gray-90);
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--gray-90);
}
