@import '../../../../../assets/sass/env';

.currency-wrapper {
  .currency-btc-icon {
    background: url('#{$s3Path}/assets/Icons/png/clutch-wallet-icon.webp');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: calc(100% - 8px);
    padding-right: 34px;
  }
}

// .currencies-options{
//   min-width: 200px;
// }
.currency-dropdown-wrap {
  @media (max-width: 576px) {
    margin: 0 0.5rem;
  }

  .currencies-options {
    padding: 1.5rem;
    min-width: 368px;
    border-radius: 0.75rem;
    color: var(--white);
    box-shadow: 0 0 1.25rem 0 var(--darkGray),
      0 0.5rem 1rem 0 var(--darkGray-80);

    @media screen and (max-width: 767.98px) {
      padding: 1rem;
    }

    @media (max-width: 576px) {
      min-width: 340px;
    }

    .balance-head-wrap {
      font-size: 16px;
      margin-bottom: 1.75rem;

      .balance-section {
        gap: 0.75rem;

        .bal-txt {
          font-weight: 700;
          line-height: 28px;
        }
      }

      .btn.btn-secondary.add-btn {
        padding: 0.5rem;
        height: 32px;
        width: 32px;
        min-height: unset;
        border-radius: 0.25rem;
      }

      .total-bal {
        font-weight: 500;

        .amount {
          color: var(--live-green);
        }
      }
    }

    .balance-box {
      font-size: 14px;
      font-weight: 600;
      background: var(--darkGray);
      padding: 12px 16px;
      border-radius: 0.5rem;

      .normal-amount {
        font-weight: 400;
      }

      .custom-switch {
        position: relative;
        background: var(--gray-80);
        width: 40px;
        height: 24px;
        border-radius: 100vh;
        transition: background 0.4s ease;

        &::before {
          top: 4px;
          bottom: auto;
          content: '';
          position: absolute;
          left: 3px;
          width: 16px;
          height: 16px;
          border-radius: 100vh;
          background: var(--gray-40);
          transition: transform 0.4s ease;
        }

        &:has(input[type='checkbox']:checked) {
          background: var(--live-green);

          &::before {
            transform: translateX(19px);
            background: var(--gray-90);
          }
        }

        input[type='checkbox'] {
          opacity: 0;
          position: absolute;
          inset: 0;
          cursor: pointer;
        }
      }

      .info-icon {
        svg {
          margin-left: 5px;
        }
      }

      .coins {
        img {
          width: 40px;
        }
      }
    }
  }

  @media screen and (max-height: 430px) {
    div#currencies_dropdown {
      max-height: 208px;
      overflow: auto;
    }
  }

  .non-cash-amount {
    color: var(--blue-300);
  }

  .dropdown-item {
    &.currency-img-header {
      padding: 0.56rem;

      img {
        width: 1rem;
      }
    }

    .currency-unit {
      min-width: 50%;
      margin-right: 0.313rem;
    }

    span {
      // min-width: 50%;
      align-items: center;
      display: flex;
      justify-content: flex-end;

      & img {
        margin-left: 0.313rem;
      }
    }
  }
}

.main-currency-wrapper {
  .currency-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: 500;
    @media (max-width: 575.98px) {
      font-size: 12px;
      margin-right: 0.5rem;
    }
  }
}

.currency-main {
  img {
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.5rem;

    @media (max-width: 576px) {
      margin-right: 0;
    }
  }

  .currency-text {
    min-width: 100px;
  }
}

.currency-notification {
  color: var(--gray-40);
  font-size: 0.875rem;
}
