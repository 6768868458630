.footer {
  $root: &;
  padding: 0 1.6875rem;
  background: var(--gray-90);

  @media screen and (max-width: 767.98px) {
    padding: 0 1rem;
  }

  .content-wrapper {
    margin: 0 auto;
  }

  .sidebar-close {
    margin-left: 66px;
  }

  & .custom-spacer {
    max-width: 1100px;
    padding: 3rem 0 1rem !important;
    @media screen and (max-width: 992.98px) {
      display: flex !important;
      flex-flow: row !important;
    }

    @media screen and (max-width: 767.98px) {
      & {
        padding: 0 !important;
      }
    }
  }

  .footer-top {
    padding: 0;

    @media screen and (max-width: 767.98px) {
      padding: 2.5rem 0 1.25rem;
    }

    @media screen and (max-width: 575px) {
      padding-bottom: 2rem;
    }

    p {
      font-size: 0.75rem;
      color: var(--gray-20);
      font-weight: 500;
      line-height: 1.063rem;
    }

    .footer-linking {
      flex-direction: column;
      padding: 0;

      a {
        display: block;
        padding: 0.313rem 0;
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--gray-20);

        &:hover {
          color: var(--blue-300);
        }
      }

      h4 {
        color: var(--white);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.2rem;
      }
    }

    .footer-about {
      .footer-logo {
        margin-bottom: 0.625rem;

        svg {
          width: 204px;
        }
      }
    }
  }

  .footer-content {
    flex: 1;
    // flex: 0 0 18.75rem;
    // max-width: 18.75rem;
    @media screen and (max-width: 991.98px) {
      flex: 1 0 25%;
    }
  }

  .footer-menu {
    flex: 1;
    padding-left: 7.5rem;
    max-width: 100%;
    gap: 2rem;

    @media screen and (max-width: 991.98px) {
      // padding-top: 2rem;
      padding-top: 0;
      // padding-left: 0;
      // flex: 0 0 100%;
      padding-left: 3rem;
      flex: 1 0 calc(75% / 2);
      // max-width: 100%;
    }
  }

  .footer-bottom {
    padding: 2rem 0;
    background: var(--gray-90);

    @media screen and (max-width: 767.98px) {
      text-align: center;
      padding: 1rem;
    }

    img {
      margin-right: 2rem;

      @media screen and (max-width: 767.98px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    p {
      font-size: 0.75rem;
      color: var(--gray-20);
      font-weight: 500;
      line-height: 1.063rem;

      @media screen and (max-width: 767.98px) {
        line-height: 1.5rem;
        margin: 0;
      }

      a {
        color: var(--white);
        padding: 0 0.2rem;
      }
    }
  }

  .language-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: 1px solid var(--gray-80);

    @media screen and (max-width: 575px) {
      border-top: 0;
      padding-top: 0;
    }

    .copyright-section {
      p {
        color: var(--gray-20);
        font-size: 0.688rem;
        font-weight: 500;

        @media screen and (max-width: 767.98px) {
          padding-bottom: 0.313rem;
        }
      }
    }

    .btn-secondary {
      width: auto;
      background: var(--selectBg);
      border: 1px solid var(--footerBorder);
      color: var(--gray-20);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      min-height: 32px;

      img {
        margin-right: 0.313rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .footer-bottom-linking {
    background: var(--darkGray);
    padding: 1.2rem;

    p {
      font-size: 0.75rem;
      color: var(--gray-20);
      font-weight: 500;
      position: relative;
      padding: 0 0.625rem;
      margin: 0;

      @media screen and (max-width: 767.98px) {
        text-align: center;
      }

      &:before {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: var(--white);
        content: '';

        @media screen and (max-width: 767.98px) {
          display: none;
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      a {
        color: var(--white);
        font-weight: 500;
        padding: 0 0.3rem;
      }
    }
  }

  .mob-linking {
    @media screen and (max-width: 768px) {
      border-bottom: 1px solid var(--gray-80);
    }
  }

  &--sidebars {
    @media screen and (min-width: 1399.98px) {
      margin-right: 1px;

      .footer-menu {
        padding-top: 2rem;
        padding-left: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.accordion-button {
  &.accordionOpen {
    padding-bottom: 0.75rem !important;
  }

  &.accordionClose {
    color: var(--white) !important;
  }

  background-color: var(--gray-90) !important;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 24px;
  padding: 1rem 0 !important;
  border: none;
  position: relative;
  text-transform: capitalize;

  &:focus {
    box-shadow: none;
  }

  &:after {
    opacity: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }

  .accordion-icon {
    svg {
      width: 32px;
    }

    position: absolute;
    right: 1rem;
  }
}

.accordion-item {
  background: transparent;
  border: none;
  margin: 0;
}

.accordion-body {
  background-color: var(--gray-90);
  color: var(--white);
  border-radius: 0 0 0.875rem 0.875rem;
  font-size: 1rem;
  padding: 0.25rem 0 1.5rem 0;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }

  ul li {
    a {
      display: block;
      padding: 0.5rem 0;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--gray-20);
    }
  }
}

.accordion-button:not(.collapsed) {
  color: var(--white);

  &:after {
    transform: none;
    opacity: 0;
  }
}

.mob-footer {
  .language-wrap {
    margin-bottom: 2rem;
  }

  .accordion-button {
    border-top: 1px solid var(--gray-80);
  }

  .accordion {
    border-bottom: 1px solid var(--gray-80);
  }
}

.accordion-animation {
  animation: 400ms ease-in-out 0s 1 normal none running accordianEffect;
}

.license-section {
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  padding-bottom: 2rem;
  margin: 0 auto;

  @media screen and (max-width: 575px) {
    padding-bottom: 2.5rem;
  }

  .age-text {
    font-size: 2rem;
    margin: 0;
  }

  .license-text {
    padding-left: 70px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }

  p {
    line-height: 1.25rem !important;
    color: var(--gray-20);
    font-size: 0.75rem;
    font-weight: 500;

    .highlight-txt {
      color: var(--white);
    }
  }

  .curacao-link {
    position: relative;
  }

  .curacao {
    width: 120px;
    height: 45px;
  }

  .verified-icon {
    width: 25px;
    height: 18.59px;
    position: absolute;
    left: 40px;
    top: 30px;
  }
}

.desktop-language {
  max-width: 1100px;
  padding-block-start: 1rem;
  padding-block-end: 1.5rem;
  margin: 0 auto;

  @media screen and (max-width: 575px) {
    padding: 0;
  }

  p {
    font-size: 0.75rem;
    color: var(--gray-20);
    font-weight: 500;
    line-height: 1.063rem;

    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}
