.rounded-16 {
  border-radius: 16px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}



.progress {
  height: 6px;
  background-color: var(--darkGray);

  &-bar {
    background-color: var(--blue-300);
  }
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}



.mt-05 {
  margin-top: 0.125rem;
}


.g-2-5, .gy-2-5 {
  --bs-gutter-y: 0.750rem;
}

.g-2-5, .gx-2-5 {
  --bs-gutter-x: 0.750rem;
}
