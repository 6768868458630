.lottieIconButton.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray-80);
  border-color: var(--gray-80);
  padding: 0;
  width: 44px;
  height: 44px;
  color: var(--gray-20);

  div {
    opacity: 0;
  }

  svg,
  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    transition: opacity 0.3s ease 0s;
  }

  &:hover,
  &.active {
    svg {
      opacity: 0;
    }

    div {
      opacity: 1;
    }
  }
}
