.casino-card-slider {

  .swiper {
    margin:  0 -0.375rem;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: .75rem 0 0;
    }
  }

  .swiper-slide {
    height: auto;
    width: 33.333%;
    min-width: 23.375rem;
    padding: 0 0.375rem;

    @media screen and (max-width: 400px) {
      width: 100%;
      min-width: unset;
    }

    & > div {
      height: 100%;
    }
  }

  &-slide {
    height: 100%;
  }
}
