#forgot-password-modal {
  z-index: 1400;

  .modal-content {
    border: 1px solid var(--gray-80);
    max-width: 468px;
    @media screen and (max-width: 575.98px) {
      max-width: 100%;
      overflow: hidden;
    }

    .btn-close {
      border-radius: 100%;
      background: var(--gray-80);
      opacity: 1 !important;
      top: 1.75rem !important;
      right: 1.5rem !important;
      @media screen and (max-width: 575.98px) {
        background: transparent !important;
        border: none !important;
        svg {
          height: 1rem;
          width: 1rem;

          path {
            fill: var(--gray-40);
          }
        }
      }
    }
  }

  .modal-body {
    padding: 1.5rem !important;
    @media screen and (max-width: 575.98px) {
      background-color: var(--gray-85) !important;
      padding: 0 !important;
    }

    .heading-text {
      font-weight: 700;
      font-size: 20px;
      @media screen and (max-width: 575.98px) {
        padding: 1.5rem !important;
        border-bottom: 1px solid var(--gray-80);
      }
    }

    .input-note {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--gray-40);
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      line-height: 0.75rem;
    }

    .auth-btn {
      border: 1px solid var(--blue-300);
      background: transparent;
      color: var(--blue-50);

      &:hover {
        background: transparent !important;
        color: var(--blue-50);
      }
    }
  }

  .modal-dialog {
    @media screen and (max-width: 575.98px) {
      position: fixed;
      top: auto;
      right: auto;
      left: auto;
      bottom: 0;
      width: 100%;
      margin: 0;
    }
  }

  .modal-dialog-centered {
    @media screen and (max-width: 575.98px) {
      min-height: auto;
    }
  }

  .forgot-pass-btn-wrap {
    @media screen and (max-width: 575.98px) {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }

  form {
    @media screen and (max-width: 575.98px) {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }
}
