@import 'swiper/scss';
@import 'swiper/scss/navigation';

.swiper-navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 1.5rem;
  position: relative;
  top: 0;
  //opacity: 0;

  &-arrow {
    border-radius: 0.313rem;
    background-color: rgba(40, 60, 81, 0.7);
    border: 1px solid rgba(40, 60, 81, 0.7);
    padding: 0.313rem 0.625rem;
    font-size: 0;
    width: 1.5rem;
    height: 1.2rem;
    display: block;
    margin-left: 0.4rem;
    position: relative;
    inset: unset;
    transform: translate(0, 0);
    transition: background-color 0.3s ease;

    &::before {
      position: absolute;
      left: 0.5rem;
      top: 0.3rem;
      content: '';
      font-size: 0;
      width: 0.313rem;
      height: 0.625rem;
      opacity: 1;
    }

    &:first-child {
      margin-left: 0;
    }

    &.swiper-button-lock {
      display: none;
    }

    &:not(.swiper-button-disabled):hover {
      background: var(--gray-60);
      border-color: var(--gray-60);
      cursor: pointer;
    }
  }

  &-prev {
    &:before {
      background: url('#{$s3Path}/assets/Icons/svg/slider-left-arrow.svg')
        no-repeat;
      background-size: 0.313rem;
    }

    &.swiper-button-disabled {
      &:before {
        background: url('#{$s3Path}/assets/Icons/svg/slider-left-arrow-grey.svg')
          no-repeat;
        background-size: 0.313rem;
      }
    }
  }

  &-next {
    &:before {
      background: url('#{$s3Path}/assets/Icons/svg/slider-right-arrow.svg')
        no-repeat;
      background-size: 0.313rem;
    }

    &.swiper-button-disabled {
      &:before {
        background: url('#{$s3Path}/assets/Icons/svg/slider-right-arrow-grey.svg')
          no-repeat;
        background-size: 0.313rem;
      }
    }
  }
}

.games-list-swiper {
  &.favLoading {
    min-height: 221.38px;
    display: flex;
    .inner-loader {
      margin: 0;
      min-height: 205px;
      width: 100%;
    }
  }
  .swiper {
    margin: 0 -0.375rem;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: 0.75rem 0 0;
    }
  }

  .swiper-slide {
    width: 14.285%;
    min-width: 160px;
    padding: 0 0.375rem;
  }
}

.providers-swiper {
  .swiper {
    margin: 0 -0.375rem;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: 0.75rem 0 0;
    }
  }

  .swiper-slide {
    width: 16.666%;
    min-width: 160px;
    padding: 0 0.375rem;
  }
}

.nft-swiper {
  .swiper {
    margin: 0 -0.375rem;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: 0.75rem 0 0;
    }
  }

  .swiper-slide {
    width: 16.666%;
    min-width: 160px;
    padding: 0 0.375rem;
  }
}

.trading-swiper {
  .swiper {
    margin: 0 -0.375rem;
  }

  .swiper-wrapper {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: 0.75rem 0 0;
    }
  }

  .swiper-slide {
    width: 33.333%;
    min-width: 374px;
    padding: 0 0.375rem;
  }
}
