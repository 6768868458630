.mob-popup {

    position: fixed;
    top: 76px;
    width: 100%;
    left: 0;
    z-index: 11;
    color: var(--white);
    background: #0f1923cc;
    border-radius: 0.875rem;
    backdrop-filter: blur(18px);

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
    .popup-title {
      font-size: .875rem;
      font-weight: 600;
    }
    .popup-subtitle {
      font-size: .75rem;
      font-weight: 400;
      color: var(--gray-40);
    }
    .icons-wrap {
      svg {
        height: 2rem;
        width: 2rem;
      }
    }
    .btn-primary {
      font-size: 0.75rem;
      min-height: 1.75rem;
    }
  }
  .add-to-screen-modal {
    &.fade .modal-dialog {
        transition: all 2s ease-out;
        bottom: 0 !important;
        min-height: auto;
        transform: none;

    }
    &.show .modal-dialog {
        transition: all 2s ease-out;
    }
    &.show {
        transform: none;
         transition: all 1s ease-out;
        .modal-dialog{
            transform: translate(0,0) !important;

            transition: all 1s ease-out;
        }
        &.fade{
            transition: all 1s ease-out !important;
        }

    }
    .modal-dialog {
       transition: transform 1s ease-out;
        transform: translate(0,300px) !important;
        bottom: 0 !important;
        left: 0% !important;
        right: 0% !important;
        position: fixed;
        min-height: auto;
        margin-bottom: 0 !important;
    }
    .modal-body {
      svg {
        width: 4.5rem;
        height: 4.5rem;
      }
      h3 {
        font-size: 1rem;
      }
      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--gray-40);
        margin-bottom: 0;
        svg {
          height: 0.875rem;
          width: 0.875rem;
        }
       span {
        color:var(--blue-300) ;
        font-weight: 600;
       }
      }
    }
  }
