.update-password-modal {
  .modal-dialog {
    max-width: 540px !important;
  }
  .modal-content  {
  overflow:hidden;
  .btn-close {
    background:var(--gray-60) ;
    opacity: 1;
    border-radius: 50%;
    border: 2px solid var(--gray-90);
  }
  }
  .modal-body {
    padding: 0 !important;
    overflow: hidden;
    .popup-bg {
      background-image: url(../../../ui-kit/Icons/svg/popup-bg.svg);
      background-size: cover;
      min-height: 290px;
      height: 100%;
      width: 100%;
       img {
        width: 185px;
        filter: drop-shadow(0 0px 15px #232222);
       }
    }
    .popup-content {
      padding: 2.5rem;
      h2 {
        font-family: "Tungsten";
        color: var(--white);
        font-size: 42px;
        margin-bottom: 1rem;
      }
      p {
        color: var(--gray-40);
        font-size: 1rem;
        span {
          color: var(--white);
        }
      }
    }
  }
}
