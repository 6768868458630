.referral-section {
  padding: 24px 0 !important;
  .form-group {
    max-width: 100% !important;
    // min-width: 600px;
    @media screen and (max-width: 767.98px) {
      min-width: 100%;
    }
    & input {
      min-width: 400px;
      @media screen and (max-width: 767.98px) {
        min-width: 100%;
      }
    }
  }
  h4 {
    margin-top: 16px;
  }
  input {
    padding-right: 120px !important;
  }
}
