.casino-card {
  $root: &;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-90);
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.15s ease-out 0s;

  &-inner {
    height: 100%;
  }

  &-left {
    padding: 1.5rem 0 0.75rem 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767.98px) {
      padding: 1.5rem .5rem 0.75rem 1rem;
    }


    .text-gradient-one,
    .text-gradient-three {
      display: block;
      background: var(--textGradientOne);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .text-gradient-two {
      display: block;
      background: var(--textGradientTwo);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .text-gradient-three {
      display: block;
      background: var(--textGradientThree);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-right {
    display: flex;
    justify-content: center;
  }

  &-img {
    max-width: 171px;
    width: 100%;
    object-fit: contain;

    @media screen and (max-width: 576px) {
      max-width: 160px;
    }

    &-newLine {
      max-width: 148px;
      margin-right: 1.5rem;
      margin-bottom: auto;

      @media screen and (max-width: 767.98px) {
        margin-right: 1rem;
      }
    }

    &-small {
      max-width: 110px;
      margin-bottom: unset;
    }
  }

  &-title {
    font-family: "Tungsten", serif;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.375rem;
    color: var(--white);
    margin-bottom: 0.3125rem;
    text-transform: uppercase;

    @media screen and (max-width: 576px) {
      font-size: 2.25rem;
      line-height: 2.375rem;
    }

    &-big {
      font-size: 3rem;
      line-height: 2.75rem;
      margin-bottom: 0.5rem;

      @media screen and (max-width: 991px) {
        font-size: 2.5rem;
        line-height: 2.375rem;
      }

      @media screen and (max-width: 576px) {
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    }

    &-gradient {
      background: linear-gradient(180deg, #fefefe 0%, #cccfd1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 18px;
    color: var(--gray-40);
    margin-bottom: 0.625rem;
  }

  &-badge {
    padding: 0.1875rem 0.5rem 0.125rem;
    text-transform: uppercase;
    color: var(--gray-90);
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 600;
    border-radius: 0.25rem;
    display: inline-block;
    margin-bottom: 0.75rem;
    width: fit-content;
  }

  &-link {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
    position: relative;
    margin-bottom: 0.625rem;
    width: fit-content;
    margin-top: auto;

    &:before {
      position: absolute;
      right: -10px;
      content: ">";
      top: 0;
    }
  }

  &-comming {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0.8125rem;
    color: var(--gray-40);
    font-weight: 500;
  }

  &-footer {
    padding: 0 1.5rem 0.75rem 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767.98px) {
      padding: 0 1rem 0.75rem 1rem;
    }
  }

  &-newLine {
    #{$root}-inner {
      height: auto;
    }

    #{$root}-left {
      padding-bottom: 0.3125rem;
    }
  }

  &-green {
    #{$root}-badge {
      background: var(--greenGradient);
    }

    #{$root}-link {
      color: var(--green);
    }
  }

  &-darkBlue {
    #{$root}-badge {
      background: var(--textGradientOne);
    }

    #{$root}-link {
      color: var(--ecosystemLinkOne);
    }
  }

  &-blue-300 {
    #{$root}-link {
      color: var(--blue-300);
    }
  }

  &-blue {
    #{$root}-badge {
      background: var(--blueGradient);
    }

    #{$root}-link {
      color: var(--blue-200);
    }
  }


  &-orange {
    #{$root}-badge {
      background: var(--orangeGradient);
    }

    #{$root}-link {
      color: var(--orange);
    }
  }

  &-sea {
    #{$root}-badge {
      background: var(--textGradientTwo);
    }

    #{$root}-link {
      color: var(--ecosystemLinkTwo);
    }
  }

  &-purple {
    #{$root}-badge {
      background: var(--textGradientThree);
    }

    #{$root}-link {
      color: var(--ecosystemLinkThree);
    }
  }

  &-pointer {
    cursor: pointer;

    &:hover {
      transform: translateY(-8px);
    }
  }

  &--sidebars {
    #{$root}-title {
      font-size: 2rem;
      line-height: 1;
    }

    #{$root}-img-newLine {
      max-width: 108px;
    }
  }
}
