a,
button {
  &.btn {
    border-radius: 0.5rem;
    line-height: 1.688rem;
    min-height: 2.75rem;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.25rem 1.5rem;
    transition: all 0.3s ease 0s;
    box-shadow: none !important;
    text-wrap: nowrap;

    &:active {
      opacity: 0.8;
      transform: scale(0.98);

      &:focus {
        box-shadow: none;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  color: var(--white);
  &.btn-primary {
    background: var(--gray-90);
    border: 1px solid var(--gray-90);

    &:hover,
    &.active,
    &:focus,
    &:active {
      background: var(--gray-60) !important;
      border: 1px solid var(--gray-60);
      color: var(--white);
    }

    &:active {
      border: 1px solid var(--gray-60);
    }
  }

  &.btn-primary-light {
    background: var(--gray-80);
    line-height: 1.25rem;
    border: 1px solid var(--gray-80);
    color: var(--gray-20);

    &:hover {
      background: var(--gray-60) !important;
      color: var(--gray-20);
      border: 1px solid var(--gray-60);
    }
  }

  &.btn-info {
    background: var(--gray-85);
    border: 1px solid var(--gray-85);
    color: var(--white);

    &:hover {
      background: var(--secondaryBtn);
      border: 1px solid var(--gray-90);
      color: var(--white);
    }

    &:active {
      border: 1px solid var(--secondaryBtn);
      color: var(--white);
    }
  }

  &.btn-secondary {
    background: var(--blue-300);
    border: none;

    &:hover {
      background: var(--blue-400);
      box-shadow: none;
    }

    &:active {
      background: var(--blue-400);
      color: var(--white);
      box-shadow: none;
    }

    &:focus {
      background: var(--blue-400);
      box-shadow: none;
    }

    &:disabled {
      background: linear-gradient(180deg, #54b8ff 0%, #1090ea 100%);
      opacity: 0.5;
      color: var(--white);
    }
  }

  &.btn-simple {
    background: var(--primaryDark);
    color: var(--white);

    &:hover,
    &:active {
      color: var(--white);
      background: var(--primaryDark);
    }
  }

  &.btn-small {
    padding: 0.5rem 1rem;
    height: 2rem;
    font-size: 1rem;
    line-height: 1;
    min-height: unset;
  }
}
