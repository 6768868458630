@import "../functions";

a.btn-outline,
button.btn-outline {
  background: transparent;
  border: 1px solid var(--blue-300);
  color: var(--blue-50);

  &:hover {
    background: transparent !important;
    border-color: var(--blue-100);
    color: var(--blue-100);
  }

  &:focus {
    background: transparent !important;
    border-color: var(--blue-200);
    color: var(--blue-200);
  }

  &[disabled] {
    background: transparent !important;
    border: 1px solid var(--blue-300);
    color: var(--blue-50);
    opacity: 50%;
  }
}

.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn-icon {
  display: inline-flex;
  align-items: center;

  svg {
    width: toRem(15);
    height: toRem(13);
    fill: var(--white);
  }

  &-left {
    padding-right: toRem(12);
  }
}

.btn-wrap {
  button {
    margin-inline: toRem(4);
  }
}
