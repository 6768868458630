@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&family=Teko:wght@600;700&display=swap');
@import '../env';
@import '../functions';

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background: var(--gray-90);
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--gray-90);
}

body {
  font-family: 'Rubik', sans-serif;
  background: var(--darkGray);
  // overflow-x: hidden;
}

// #root {
// overflow-x: hidden;
// }

.rounded-16 {
  border-radius: 16px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

// .btn {
//   font-size: 1rem;
//   padding: 4px 15px;
//   border: none;
// }

.table.table-default {
  margin-bottom: 0;

  thead {
    tr {
      background: var(--aquaBlue);

      th {
        width: 1%;
        font-weight: 400;
        padding: 16px 10px;
        border: none;
        color: var(--white);

        &:first-child {
          border-radius: 14px 0 0 0;
        }

        &:last-child {
          border-radius: 0 14px 0 0;
        }

        font-size: 14px;
      }
    }
  }

  tbody {
    tr {
      td {
        color: var(--white);
        font-weight: 400;
        border: none;
        padding: 14px 10px;
        font-size: 13px;
        width: 5%;

        @media screen and (min-width: 768px) {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .table-data-success {
        color: var(--green);
      }

      &:nth-child(even) {
        background: var(--darkSlate);
      }

      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 18px;
          }

          &:last-child {
            border-radius: 0 0 18px 0;
          }
        }
      }
    }
  }
}

.form-control {
  background-color: var(--gray-90);
  border: 1px solid var(--gray-90);
  color: var(--white);
  padding: 0.438rem 0.75rem;
  border-radius: 0.625rem;
  height: 3rem;
  font-size: 1rem;

  &::placeholder {
    color: var(--gray-20) !important;
  }

  &:focus {
    color: var(--white);
    border: 1px solid var(--blue-300);
    background-color: var(--gray-90);
    outline: none;
    box-shadow: none;
  }

  &:focus-within {
    box-shadow: none;
  }

  &:disabled,
  &[readonly] {
    background-color: var(--gray-90);
    color: var(--white);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &-dark {
    background-color: var(--darkGray);
    border: 1px solid var(--darkGray);

    &:focus {
      border: 1px solid var(--blue-300);
      background-color: var(--darkGray);
      outline: none;
    }
  }
}

.auth-img {
  height: 100%;
  overflow: hidden;
  border-radius: 0px 15px 15px 0px;
  position: relative;
}

.auth-img img {
  position: absolute;
  width: 100%;
  bottom: 0;
  object-fit: contain;
}

.auth-img--betaFilled img {
  height: 100%;
  object-fit: cover;
  top: 0 !important;
}

.auth-hint-text {
  p {
    font-size: 1rem;
    color: var(--gray-40);
    font-weight: 500;
    line-height: toRem(20);
    text-align: center;
  }
}

.auth-mob-instructions {
  padding: 1rem 0 0;
  // padding: 0.5rem 0 0;

  @media screen and (max-width: 575.98px) {
    &.pb-5 {
      padding-bottom: 0;
    }
  }

  p {
    font-size: 0.75rem;
    color: var(--white);
    font-weight: 600;
    line-height: 1rem;
    margin: 0;
    text-align: center;

    a {
      color: var(--blue-300);
      padding-left: 0.2rem;
    }
  }

  &--betaFilled {
    &.mb-0 {
      margin-bottom: 0 !important;
    }
    p {
      color: var(--gray-40);
      font-size: toRem(14);
      line-height: toRem(20);
    }

    a {
      vertical-align: text-bottom;

      svg {
        fill: var(--blue-300);
      }
    }
  }
}

.auth-instruction-text {
  position: absolute;
  width: 100%;
  bottom: 1rem;
  text-align: center;
  padding: 0 1rem;

  p {
    font-size: 0.875rem;
    color: var(--white);
    font-weight: 600;
    line-height: 20px;
    margin: 0;

    a {
      color: var(--blue-300);
      padding-left: 0.2rem;
    }
  }
}

.progress {
  height: 3px;
  background-color: var(--darkGray);
}

.text-green {
  color: var(--green);
}

.text-red {
  color: var(--red);
}

.modal .modal-content {
  background-color: var(--gray-90);
  border-radius: 15px;
  color: var(--white);

  @media screen and (max-width: 575.98px) {
    border-radius: 0;
  }

  .modal-body {
    padding: 1.875rem 1.25rem;

    @media screen and (max-width: 1199.98px) {
      padding: 1.875rem 1rem 5rem 1rem;
    }
  }

  .btn-close {
    width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    background-color: var(--gray-60);
    border: 2px solid var(--gray-90);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0;
    position: absolute;
    top: 0.375rem;
    right: 0.25rem;

    svg {
      width: 0.625rem;
      height: 0.625rem;
    }
  }

  .heading-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;

    .modal-title {
      color: var(--white);
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.75rem;
      text-align: center;
    }
  }

  .body-wrap {
    .body-heading {
      color: var(--white);
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .form-group {
      margin-bottom: 1.125rem !important;

      .form-label {
        color: var(--white);
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;

        &.form-label-grey {
          color: var(--white);
          margin-left: 0.5rem;
        }
      }

      .custom-input-group-addons {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        z-index: 6;
        padding: 0.625rem 0.5rem;

        img {
          width: 1.25rem;
          max-width: 1.25rem;
          max-height: 1.25rem;
        }

        .input-btn {
          height: 1.875rem;
          background-color: var(--game-color-400);
          border: 0;
          border-radius: 0.313rem;
          color: var(--game-text-100);
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.125rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.375rem 0.625rem;

          svg {
            width: 1.25rem;
            height: 1.25rem;
            fill: var(--game-text-100);

            path {
              fill: var(--game-text-100);
            }
          }

          &:hover,
          &.active {
            background-color: var(--game-primary);
            color: var(--white);

            svg {
              fill: var(--white);

              path {
                fill: var(--white);
              }
            }
          }
        }
      }

      .input-group {
        position: relative;

        .form-control {
          width: 100%;
          height: 3.25rem;
          background-color: var(--game-color-300);
          border: 1px solid var(--game-border-200);
          border-radius: 0.375rem;
          color: var(--game-text-100);
          font-size: 0.875rem;
          font-weight: 700;
          padding: 0.625rem 0.75rem;
          margin: 0;
        }
      }
    }

    .btn-box {
      display: flex;
      flex-direction: column;
      gap: 0.938rem;
      margin-top: 0.313rem;

      .btn {
        border-radius: 0.375rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.125rem;
        padding: 1rem;
      }

      .btn-place-bet {
        background-color: var(--game-primary);
        border: 2px solid var(--game-primary);
        color: var(--white);
      }

      .btn-place-bet-outline {
        background-color: transparent;
        border: 2px solid var(--game-border-300);
        color: var(--game-text-100);
      }

      .btn-text {
        background-color: transparent;
        border: 0;
        color: var(--white);
      }
    }

    ol {
      li {
        font-size: 0.875rem;

        &:not(:last-child) {
          margin-bottom: 0.125rem;
        }

        &.game-rules-text {
          color: var(--game-text-100);
        }
      }
    }

    hr {
      background-color: var(--game-border-100);
      opacity: 1;
      margin: 1rem 0;
    }
  }
}

.form-group {
  position: relative;

  & .show-password {
    position: absolute;
    right: 10px;
    top: 38px;
    color: var(--gray-20);
    cursor: pointer;
  }
}

.mt-05 {
  margin-top: 0.125rem;
}

.form-label {
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: var(--gray-40);

  span {
    color: var(--red);
  }
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235A7DA1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
}

.game-container {
  max-width: 1106px;
  background-image: linear-gradient(var(--containerBg), var(--containerBg)),
    linear-gradient(180deg, var(--containerBorder), var(--containerBg) 60%);
  border: 2px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 0.625rem;
  margin: 0 auto;

  .game-wrapper {
    border-bottom: 2px solid var(--game-border-100);
    display: flex;

    .game-sidebar {
      width: 100%;
      max-width: 309px;
      border-right: 2px solid var(--game-border-100);

      .game-sidebar-wrap {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        .nav.nav-tabs {
          width: 100%;
          background-color: var(--game-color-100);
          border: 0;
          border-radius: 0.5rem;
          gap: 0.313rem;
          padding: 0.375rem;
          margin-bottom: 0.938rem;

          .nav-item {
            min-height: 2.25rem;
            flex-grow: 1;

            .nav-link {
              background-color: transparent;
              color: var(--game-text-100);
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.25rem;
              text-transform: uppercase;
              padding: 0.5rem;

              &:hover {
                color: var(--white);
              }

              &.active {
                background-color: var(--game-color-200);
                color: var(--white);
                border: none;
              }
            }
          }
        }

        .form-group {
          margin-bottom: 0.625rem;

          .form-label {
            color: var(--white);
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            margin-bottom: 0.25rem;

            &.form-label-grey {
              color: var(--white);
              margin-left: 0.5rem;
            }
          }

          .custom-input-group-addons {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            z-index: 6;
            padding: 0.625rem 0.5rem;

            img {
              width: 1.25rem;
              max-width: 1.25rem;
              max-height: 1.25rem;
            }

            .input-btn {
              height: 1.5rem;
              background-color: var(--game-color-400);
              border: 0;
              border-radius: 0.313rem;
              color: var(--game-text-100);
              font-size: 0.75rem;
              font-weight: 500;
              line-height: 1.125rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.188rem 0.375rem;

              svg {
                width: 1.25rem;
                height: 1.25rem;
                fill: var(--game-text-100);

                path {
                  fill: var(--game-text-100);
                }
              }

              &:hover,
              &.active {
                background-color: var(--game-primary);
                color: var(--white);

                svg {
                  fill: var(--white);

                  path {
                    fill: var(--white);
                  }
                }
              }
            }
          }

          .input-group {
            position: relative;

            .form-control {
              width: 100%;
              height: 2.75rem;
              background-color: var(--game-color-300);
              border: 1px solid var(--game-border-200);
              border-radius: 0.375rem;
              color: var(--game-text-100);
              font-size: 0.875rem;
              font-weight: 700;
              padding: 0.625rem 0.75rem;
              margin: 0;
            }
          }
        }

        .btn-box {
          display: flex;
          flex-direction: column;
          gap: 0.938rem;
          margin-top: 0.313rem;

          .btn {
            border-radius: 0.375rem;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.125rem;
            padding: 1rem;
          }

          .btn-place-bet {
            background-color: var(--game-primary);
            border: 2px solid var(--game-primary);
            color: var(--white);
          }

          .btn-place-bet-outline {
            background-color: transparent;
            border: 2px solid var(--game-border-300);
            color: var(--white);
          }

          .btn-text {
            background-color: transparent;
            border: 0;
            color: var(--white);
          }
        }

        .risk-btn-box {
          display: flex;
          gap: 0.375rem;

          .risk-btn {
            background-color: var(--game-color-500);
            border: 1px solid var(--game-border-200);
            border-radius: 0.375rem;
            color: var(--white);
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
            flex-grow: 1;
            padding: 0.75rem;

            &:hover,
            &.active {
              background-color: var(--game-primary);
              border: 1px solid var(--game-primary);
              color: var(--white);
            }
          }
        }

        .bet-win-loss-box {
          border: 1px solid var(--game-border-100);
          border-radius: 0.375rem;
          padding: 1rem 1.25rem;
          margin-bottom: 0.625rem;

          hr {
            background-color: var(--game-border-100);
            opacity: 1;
            margin: 0.625rem 0;
          }

          .form-group {
            margin-bottom: 0;

            .input-group {
              .custom-input-group-addons {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
              }

              .form-control {
                height: unset;
                background-color: transparent;
                border: 0;
                padding: 0;
              }
            }
          }
        }

        .higher-lower-btn-box {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
          margin-bottom: 0.625rem;

          .higher-lower-btn {
            background-image: linear-gradient(
                var(--game-color-500),
                var(--game-color-500)
              ),
              linear-gradient(
                180deg,
                var(--game-border-200),
                var(--game-color-500) 60%
              );
            border: 1px solid transparent;
            background-origin: border-box;
            background-clip: content-box, border-box;
            border-radius: 0.375rem;
            padding: 0;

            .higher-lower-inner {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1.375rem;
              padding: 0.625rem;

              .text {
                color: var(--white);
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.125rem;
              }

              .inner-text-box {
                height: 1.5rem;
                background-color: var(--game-color-400);
                border-radius: 0.313rem;
                color: var(--blue-100);
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.125rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.25rem;
                padding: 0.125rem 0.25rem;

                img {
                  width: 1rem;
                  height: 1rem;
                }
              }
            }

            &:hover {
              background: var(--game-primary);
            }

            &:disabled {
              pointer-events: all;
              cursor: not-allowed;
            }

            &:disabled:hover {
              pointer-events: all;
              cursor: not-allowed;
              background-image: linear-gradient(
                  var(--game-color-500),
                  var(--game-color-500)
                ),
                linear-gradient(
                  180deg,
                  var(--game-border-200),
                  var(--game-color-500) 60%
                );
            }
          }
        }
      }
    }

    .game-holder {
      width: 100%;
    }
  }

  @media screen and (max-width: 991.98px) {
    max-width: 400px;

    .game-wrapper {
      flex-direction: column;

      .game-sidebar {
        max-width: unset;
        border-top: 2px solid var(--game-border-100);
        border-right: 0;

        .game-sidebar-wrap {
          flex-direction: column-reverse;

          .btn-box {
            margin-bottom: 0.938rem;
          }
        }
      }
    }
  }

  .custom-range-group {
    .form-range {
      width: 100%;
      height: 4px;
      border-radius: 0.5rem;
      outline: none;
      transition: background 450ms ease-in;
      appearance: none;
      -webkit-appearance: none;

      &::-webkit-slider-runnable-track {
        height: 4px;
        background-color: var(--rangeSliderTrack);
      }

      &::-webkit-slider-thumb {
        width: 18px;
        height: 18px;
        background: var(--white);
        border: 4px solid var(--blue-300);
        border-radius: 50%;
        cursor: ew-resize;
        margin-top: -7px;
        appearance: none;
        -webkit-appearance: none;
      }
    }
  }

  .game-settings-wrap {
    .btn-group {
      .dropup-btn {
        background-color: transparent;
        border: 0;
        position: relative;

        &:hover {
          svg {
            fill: var(--white);
          }
        }

        &[aria-expanded='true'] {
          &::before {
            content: '';
            width: 0px;
            height: 0px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--game-border-100);
            position: absolute;
            top: -11px;
            left: 6px;
          }

          svg {
            fill: var(--game-primary);
          }
        }
      }

      .dropdown-menu {
        min-width: 150px;
        background: var(--game-color-100);
        border: 2px solid var(--game-border-100);
        border-radius: 0.375rem;
        margin-left: 4px !important;

        .dropdown-item {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 0.613rem 0.75rem;
          transition: 0.2s all;
          -webkit-transition: 0.2s all;

          .icon {
            width: 0.875rem;
            min-width: 0.875rem;
            height: 0.875rem;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 100%;
              height: 100%;
              fill: var(--white);
            }
          }

          .text {
            color: var(--white);
            font-size: 0.825rem;
          }

          .custom-range-group {
            display: flex;
            align-items: center;
          }

          &:hover {
            background-color: var(--game-color-200);
          }

          &.active,
          &.active:hover {
            background-color: var(--game-primary);
          }
        }

        span.dropdown-item {
          &:hover,
          &.active,
          &.active:hover {
            background-color: transparent;
          }
        }
      }
    }

    .dragable-live-state {
      .dropdown-menu {
        min-width: 250px;
        background: transparent;
        border: transparent;

        .live-state-dropdown-menu {
          background: var(--game-color-100);
          border: 2px solid var(--game-border-100);
          border-radius: 0.375rem;

          .live-state-head {
            background-color: var(--game-border-100);
            cursor: move;
          }

          .live-state-body {
            font-size: 0.825rem;
          }
        }
      }
    }

    .game-foot-logo {
      max-width: 165px;
      width: 100%;
    }
  }

  .fairness-modal-button {
    color: var(--themeGreyText);
    cursor: pointer;

    &::selection {
      background: transparent;
    }

    &:hover {
      color: var(--white);
    }

    &:active {
      opacity: 0.6;
    }
  }
}

.mine-more-info {
  color: var(--white);
  font-size: 12px;
  opacity: 0.4;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}
