.modal {
  .modal-dialog {

    @media (max-width: 576px) {
      margin: 1rem;

    }
  }


  .modal-content {
    background-color: var(--gray-90);
    border-radius: 15px;
    color: var(--white);

    .modal-header {
      font-size: 1rem;
      line-height: 1.25;
      font-weight: 700;
      border-bottom: 1px solid var(--gray-80);
      padding: .8rem 1rem .75rem;
    }


    @media screen and (max-width: 575.98px) {
      border-radius: 8px;
    }

    .modal-body {
      padding: 1.875rem 1.25rem;

      @media screen and (max-width: 1199.98px) {
        padding: 1.5rem 1rem 1.5rem 1rem;
      }
    }

    .btn-close {
      width: 1.5rem;
      max-width: 1.5rem;
      height: 1.5rem;
      background-color: var(--gray-60);
      border: 2px solid var(--gray-90);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      padding: 0;
      position: absolute;
      top: .5rem;
      right: 1rem;

      svg {
        width: 0.625rem;
        height: 0.625rem;
      }
    }

    .heading-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      position: relative;

      .modal-title {
        color: var(--white);
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.75rem;
        text-align: center;
      }
    }

    .body-wrap {
      .body-heading {
        color: var(--white);
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .form-group {
        margin-bottom: 1.125rem !important;

        .form-label {
          color: var(--white);
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
          margin-bottom: 0.25rem;

          &.form-label-grey {
            color: var(--white);
            margin-left: 0.5rem;
          }
        }

        .custom-input-group-addons {
          display: flex;
          align-items: center;
          gap: 0.375rem;
          z-index: 6;
          padding: 0.625rem 0.5rem;

          img {
            width: 1.25rem;
            max-width: 1.25rem;
            max-height: 1.25rem;
          }

          .input-btn {
            height: 1.875rem;
            background-color: var(--game-color-400);
            border: 0;
            border-radius: 0.313rem;
            color: var(--game-text-100);
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.375rem 0.625rem;

            svg {
              width: 1.25rem;
              height: 1.25rem;
              fill: var(--game-text-100);

              path {
                fill: var(--game-text-100);
              }
            }

            &:hover,
            &.active {
              background-color: var(--game-primary);
              color: var(--white);

              svg {
                fill: var(--white);

                path {
                  fill: var(--white);
                }
              }
            }
          }
        }

        .input-group {
          position: relative;

          .form-control {
            width: 100%;
            height: 3.25rem;
            background-color: var(--game-color-300);
            border: 1px solid var(--game-border-200);
            border-radius: 0.375rem;
            color: var(--game-text-100);
            font-size: 0.875rem;
            font-weight: 700;
            padding: 0.625rem 0.75rem;
            margin: 0;
          }
        }
      }

      .btn-box {
        display: flex;
        flex-direction: column;
        gap: 0.938rem;
        margin-top: 0.313rem;

        .btn {
          border-radius: 0.375rem;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.125rem;
          padding: 1rem;
        }

        .btn-place-bet {
          background-color: var(--game-primary);
          border: 2px solid var(--game-primary);
          color: var(--white);
        }

        .btn-place-bet-outline {
          background-color: transparent;
          border: 2px solid var(--game-border-300);
          color: var(--game-text-100);
        }

        .btn-text {
          background-color: transparent;
          border: 0;
          color: var(--white);
        }
      }

      ol {
        li {
          font-size: 0.875rem;

          &:not(:last-child) {
            margin-bottom: 0.125rem;
          }

          &.game-rules-text {
            color: var(--game-text-100);
          }
        }
      }

      hr {
        background-color: var(--game-border-100);
        opacity: 1;
        margin: 1rem 0;
      }
    }
  }
}
