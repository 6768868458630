:root {
  --white: #ffffff;
  --white-90: rgba(255, 255, 255, 0.9);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-015: rgba(255, 255, 255, 0.15);
  --white-005: rgba(255, 255, 255, 0.05);
  --black: #000000;
  --darkGray: #0F1923;
  --darkGray-80: rgba(15, 25, 35, 0.8);
  --darkGray-40: rgba(15, 25, 35, 0.4);
  --gray-2: #FBFBFB;
  --gray-5: #F1F1F1;
  --gray-10: #E7E7E7;
  --gray-10-80: #BFC2C6;
  --gray-20: #8A90A2;
  --gray-40: #6B758B;
  --gray-60: #404D6A;
  --gray-80: #283C51;
  --gray-80-50: rgba(40, 60, 81, 0.5);
  --gray-85: #1D2C3C;
  --gray-90: #162331;
  --blue-50: #B0E3FD;
  --blue-100: #8AD5FD;
  --blue-200: #54B8FF;
  --blue-300: #00A4FA;
  --blue-300-20: rgba(0, 164, 250, 0.2);
  --blue-400: #1090EA;
  --blue-500: #0083C8;
  --blue-600: #007BBC;
  --blue-700: #006296;
  --blue-800: #004A70;
  --blue-900: #003958;
  --live-green: #72F238;
  --sport-green: #A2D772;
  --live-green-016: rgba(114, 242, 56, 0.16);
  --yellow: #FCD443;
  --orange: #FFB018;
  --pink: #FF007A;
  --red: #FF4949;
  --greenGradient: linear-gradient(90deg, #B3FF6F 0%, #A2D772 112.5%);
  --blueGradient: linear-gradient(90deg, #54B8FF 0%, #0083C8 100%);
  --orangeGradient: linear-gradient(90deg, #FFB018 0%, #E9AE1C 112.5%);

  --primaryBtnHover: linear-gradient(to right, rgba(33, 168, 247, 1) 0%, rgba(53, 179, 249, 1) 100%);
  --secondaryBtn: linear-gradient(to right, rgba(33, 168, 247, 1) 0%, rgba(53, 179, 249, 1) 100%);
  --secondaryRadialBtn: linear-gradient(180deg, var(--blue-200) 0%, var(--blue-400) 100%);
  --secondaryBtnBorder: rgba(255, 255, 255, 0.45);
  --primaryGradient: linear-gradient(90deg, #283C51 0%, rgba(22, 35, 49, 0.00) 100%);
  --themeCardTopGradient: linear-gradient(180deg, rgba(68, 94, 122, 1) 0%, rgba(37, 57, 79, 0)100%);
  --sidebarMenuBg: linear-gradient(180deg, #162331 0%, #1F3246 100%);
  --liveMatchCountBg: linear-gradient(180deg, #273A4D 0%, #1A2835 100%);
  --liveMatchCountBorder: rgba(255, 255, 255, 0.04);
  --inputBoxShadowOne: rgb(45 174 247);
  --inputBoxShadowTwo: rgb(72 177 251);
  --gameOnGradient: linear-gradient(180deg, #FFEBA9 0%, #E4B863 100%);
  --cryptoSellBtn: rgb(255, 73, 73);
  --cryptoSellBtnBg: rgba(255, 73, 73, 0.13);
  --cryptoSliderBg: linear-gradient(90deg, rgb(114, 242, 56) 0%, rgb(255, 176, 24) 50%, rgb(255, 73, 73) 100%);
  --table-fade: linear-gradient(to bottom, rgba(30, 87, 153, 0.01) 0%, rgba(15, 25, 35, 1) 100%);
  --selectBg: rgba(49, 58, 66, 0.50);
  --overlayBg: rgb(23 37 54 / 91%);
  --webBannerOverlay: linear-gradient(90deg, #15222F -0.86%, rgba(15, 25, 35, 0.70) 18.12%, rgba(15, 25, 35, 0.00) 38.19%);
  --authPrimryBtn: radial-gradient(90.91% 90.91% at 50.00% 9.09%, #1B2A3C 0%, #131F2D 100%);
  --providerCard: linear-gradient(223deg, #162331 0%, #111D28 100%);
  --sidebarBtnGradient: linear-gradient(270deg, #0096FF -1.3%, #48BDFF 99.93%);
  --textGradientOne: linear-gradient(90deg, #526FFF 0%, #9CACFF 90.91%);
  --textGradientTwo: linear-gradient(90deg, #36CAC5 0%, #8FFFEE 38.87%);
  --textGradientThree: linear-gradient(270deg, #C776FF 8.01%, #A331F1 100%);
  --loaderBg: rgb(15 25 35 / 85%);
  --sliderGradient:linear-gradient(90deg, #0493ED 0.9%, #810CFF 33.25%, #FD2E6E 100%);
  --rewardsBannerBg:rgb(23 37 54 / 75%);
  --dropdownShadow: 0px 0px 16px 0px #0F1923F5;
  --chatUserOne: #9F735C;
  --statusColorOne: #BE6BFF;
  --tipAmountText: #eb9b29;
  --ecosystemLinkOne: #5270FF;
  --ecosystemLinkTwo: #3CCDC7;
  --ecosystemLinkThree: #AE55ED;

  --footerBorder: #323B45;

  --mineTableCellBg01: #32465C;
  --mineTableCellBg02: #203041;
  --mineTableCellCoinBg01: #182935;
  --mineTableCellCoinBg02: #76C5FF;
  --mineTableCellCoinBg03: #0068B4;
  --mineTableCellMineBg01: #7976FF;
  --mineTableCellMineBg02: #4F1AC1;
  --mineTableCellBorder01: #49617C;
  --mineTableCellBorder02: #3BABFC;
  --mineTableCellBorder03: #793BFC;
  --btnBgGreyHover: #3d5564;
  --minePlachholder: #657D97;
  --green: #00e701;
  --themeGreyText: #30475F;

  // plinko game variables
  --riskBtnBg: #1B2938;
  --riskBtnBorder: #213142;
  --rangeSliderTrack: #23374C;

  // flip-coin game variables
  --flip-coin-text: rgba(34, 32, 32, 0.85);
  --flip-coin-gradient: linear-gradient(93.45deg, #FF7A00 0%, #9CE800 139.58%);
  --flip-coin-bg: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), #F6C100;


  // Hi-lo game variables
  --hoverBorder: #78CDFD;
  --card-bg: #7EFF43;

  // Game Color Pallete
  --containerBg: #162331;

  --containerBorder: #26384b;

  --game-primary: #21A8F7;

  --game-color-100: #111B26;
  --game-color-200: #1F2E3E;
  --game-color-300: #131D28;
  --game-color-400: #26374A;
  --game-color-500: #1B2938;
  --game-color-600: #0F1923;
  --game-color-700: #32465C;
  --game-color-800: #203041;
  --game-color-900: #151E28;

  --game-text-100: #657D97;
  --game-text-200: #90B4D9;
  --game-text-300: #526C88;

  --game-border-100: #25394F;
  --game-border-200: #213142;
  --game-border-300: #465D77;
  --game-border-400: #212D3A;
  --game-border-500: #30475F;

  // Game Color coin fllip
  --flip-coin-game-border: rgba(68, 94, 122, 0.5);
  --range-slider-bg-800: #182534;
  --flip-coin-checkbox-border: #192837;
  --flip-coin-selctbox: #223346;
  --flip-coin-addon-btn-bg: #1F2E3F;
  --rangeSliderBg1: #0C8BEE;
  --rangeSliderBg2: #F82C74;
}
