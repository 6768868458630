.cashCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gray-85);
  padding: 2rem 0.5rem 1.625rem;
  text-align: center;
  border-radius: 0.313rem;
  cursor: pointer;
  height: 100%;
  transition: background-color 0.3s;
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
    img {
      filter: grayscale(100%);
    }
    &:hover {
      background: var(--gray-80);
    }
  }
  @media screen and (max-width: 767.98px) {
    padding: 1.5rem 0.5rem 1.25rem;
  }

  &:hover {
    background: var(--gray-80);
  }

  &-icon {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin: 0 auto 0.625rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  h4 {
    color: var(--white);
    margin: 0;
    font-weight: 700;
    font-size: 1rem;

    @media screen and (max-width: 767.98px) {
      font-size: 0.8125rem;
    }
  }

  p {
    color: var(--gray-40);
    text-transform: capitalize;
    margin: 0;
    font-size: 0.875rem;

    @media screen and (max-width: 767.98px) {
      font-size: 0.75rem;
    }
  }
}
