.nav-scroll-smooth {
  scroll-behavior: smooth;
}

.nav-scroll-drag {
  scroll-behavior: unset !important;
}

.nav-scroll-arrow {
  position: relative;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.313rem;
  background: var(--gray-80);
  border: 1px solid var(--gray-80);
  padding: 0.313rem 0.625rem;
  font-size: 0;
  width: 1.5rem;
  height: 1.5rem;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background: url(https://metaversity-dev-storage.s3.amazonaws.com/assets/Icons/svg/slider-right-arrow.svg);
    background-repeat: no-repeat;
    font-size: 0;
    background-size: 0.313rem;
    width: 0.313rem;
    height: 0.625rem;
    opacity: 1;
  }
}

.nav-scroll-arrow-wrapper {
  position: absolute;
  width: 3rem;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(270deg, #0f1923 0%, rgba(15, 25, 35, 0) 100%);
}
.nav-scroll-arrow-wrapper.left {
  left: 0;
  right: unset;
  transform: rotate(180deg);
}

.nav-scroll-wrapper {
  position: relative;
  width: 100%;

  .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    padding-bottom: 0 !important;
    margin-bottom: 0;
    list-style: none;
    scrollbar-width: none;
    white-space: nowrap;
    overflow: auto hidden;
    -webkit-box-align: center;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
