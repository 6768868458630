@import '../../../../assets/sass/functions';

.bannerImage {
  object-fit: contain !important;
}

.footer {
  margin-top: toRem(32);
  margin-bottom: 0;
}
