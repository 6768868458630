.cropper-view-box {
  border-radius: 50%;
}

.cropper-face {
  background-color: transparent !important;
  left: unset !important;
  top: unset !important;
}

.cropper-move {
  cursor: grab;
}

.cropper-container {
  width: 160px !important;
  height: 160px !important;

  .cropper-wrap-box,
  .cropper-canvas {
    margin: auto;
  }
}

.cropper-crop-box {
  pointer-events: none;
  // width: 124px !important;
  // height: 124px !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.cropper-view-box {
  outline: none;
}

.cropper-center {
  display: none;
}

.cropper-modal {
  background-color: var(--gray-80);
}

.preview-heading {
  width: 206px;
  height: 28px;
}

.preview-wrap {
  width: 160px;
  height: 160px;
  overflow: hidden;
}

.preview-wrap img {
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  // margin: -20px 0 0 -20x;
  // transform: scale(2.5);
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: var(--white);
}
