.input-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &--withIcon {
    .form-control {
      padding-left: toRem(44);
    }

    .input-icon {
      position: absolute;
    }
  }
}

.input-icon {
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  display: flex;
  z-index: 10;

  svg {
    width: toRem(14);
    height: toRem(12);
    vertical-align: unset;
  }
}
