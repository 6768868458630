.flip-coin-pick-side {
  @media screen and (min-width: 992px) {
    min-width: 233px;
  }

  .btn-check-label {
    border-radius: 50em;
    background-color: transparent;
    border: 2px solid transparent;
    opacity: 0.2;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      width: 50px;
      height: 50px;

      @media screen and (min-width: 767px) {
        width: 74px;
        height: 74px;
      }
    }
  }

  .btn-check:checked + .btn-check-label {
    position: relative;
    opacity: 1;
  }
}
