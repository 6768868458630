.notification-content-scroll{
  height: calc(100vh - 120px);
  overflow-y: auto;
  @media screen and (max-width: 1199.98px){
    height: calc(100vh - 165px);
  }
  @media screen and (max-width: 767.98px){
    height: calc(100vh - 175px);
  }
  .empty-chat{
    // width: 100%;
  }
  .notification-pinned-wrap {
    padding: 0 1rem;
  }
  .notification-content-wrap{
    margin-top: 0.25rem;
    padding: 0 1rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    position: relative;
    .games-not-found{
      span{
        display: block;
        text-align: center;
        word-break: break-word;
        background: var(--gray-80);
      }
    }
    @media screen and (max-width: 767.98px){
      width: 100%;
      height: calc(100vh - 260px);
    }
    .chat-card{
      margin: 0.313rem 0;
      border-radius: 0.625rem;
      background: var(--gray-80-50);
      padding: 0.625em;
      color: var(--white);
      word-break: break-word;
      position: relative;
      h4{
        font-size: 0.875rem;
        font-weight: 600;
        padding-right: 0.313rem;
        cursor: pointer;
        margin: 0.4rem 0 0;
      }
      p{
        span{
          color: var(--white);
          opacity: 0.8;
          font-weight: 500;
        }
      }
      p {
        font-size: 0.8rem;
        color:var(--gray-20);
        line-height: 1.2;
        margin: 0;
      }
      .user-icon{
        width: 1rem;
        margin-right:0.313rem;
      }
      &:first-child{
        margin-top: auto;
      }
      .chat-card-head{
        position: absolute;
        right: 0.313rem;
        top: 0.313rem;
        .chat-badge-lising{
          margin: 0 0.1rem;
          display: flex;
          svg{
            margin: 0 0.1rem;
          }

        }
        .user-clr-one{
          color: var(--chatUserOne);
        }
        h4{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 90px;
        }
      }
      .chat-time{
        font-size: 0.625rem;
        font-weight: 300;
        color: var(--gray-20);
      }
    }
  }
}

.notification-header{
  margin-bottom: 1rem;

  span{
    font-weight: 600;
    color: var(--gray-20);
  }

}
