.table.table-default {
  margin-bottom: 0;

  thead {
    tr {
      background: var(--aquaBlue);

      th {
        width: 1%;
        font-weight: 400;
        padding: 16px 10px;
        border: none;
        color: var(--white);

        &:first-child {
          border-radius: 14px 0 0 0;
        }

        &:last-child {
          border-radius: 0 14px 0 0;
        }

        font-size: 14px;
      }
    }
  }

  tbody {
    tr {
      td {
        color: var(--white);
        font-weight: 400;
        border: none;
        padding: 14px 10px;
        font-size: 13px;
        width: 5%;

        @media screen and (min-width: 768px) {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .table-data-success {
        color: var(--green);
      }

      &:nth-child(even) {
        background: var(--darkSlate);
      }

      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 18px;
          }

          &:last-child {
            border-radius: 0 0 18px 0;
          }
        }
      }
    }
  }
}

.theme-table {
  color: var(--white);
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  vertical-align: middle;

  .user_rank {
    width: 32px;
    height: 32px;
  }

  thead {
    th {
      padding: 0.5rem 1rem;
      color: var(--gray-20);
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      border: none;
      background: var(--gray-85);
      text-align: left !important;

      @media screen and (max-width: 767.98px) {
        font-size: 0.75rem;
      }

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }

  tbody {
    tr {
      td {
        border: none;
        background: var(--gray-90);
        font-weight: 500;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        font-size: 0.875rem;
        text-align: left;

        &:first-child {
          white-space: nowrap;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }

        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
  }

  &-bets {
    tbody {
      tr {
        td {
          height: 48px;
        }
      }
    }

    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
  }
}
