#login_modal.modal {
  .modal-dialog {
    @media screen and (max-width: 575.98px) {
      // margin: 2rem;
      margin: 0;
    }
    .modal-content {
      @media screen and (max-width: 575.98px) {
        height: 100%;
        width: calc(100% - 2rem);
        margin: 0 auto;
      }
    }
  }
  .register-wrap {
    @media screen and (max-width: 575.98px) {
      padding: 0.75rem 1.75rem;
      overflow-y: auto;
    }
    &.pb-130 {
      padding-bottom: 130px;
    }
  }
  .full {
    .phoneWithCode {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
      .codeNumber {
        width: 30%;
      }
      .phoneNumber {
        width: 70%;
      }
    }
  }
  .half {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    .form-group {
      width: 50%;
    }
  }
  .auth-fixed {
    position: absolute;
    left: 1.5rem;
    bottom: 0;
    width: 350px;
    background: #162331;
    padding-bottom: 1rem;
    @media screen and (max-width: 991.98px) {
      width: calc(100% - 3.2rem);
      left: 1.5rem;
    }
    @media screen and (max-width: 575.98px) {
      width: calc(100% - 2.2rem);
      left: 1rem;
    }
  }
  .mob-login-header {
    @media screen and (max-width: 575.98px) {
      padding: 0.75rem 1.75rem 1rem;
      background-color: var(--gray-85);
      border-bottom: 1px solid var(--gray-80);
    }
  }
  .login-img-wrapper {
    max-height: 600px;
    overflow: hidden;

    @media screen and (max-width: 1024.98px) {
      display: none !important;
    }
  }
  .mob-login {
    max-height: 600px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--darkGray-90);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      // background: var(--white);
      background: var(--gray-60);
      border-radius: 10px;
    }
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #b30000;
    // }
    @media screen and (max-width: 575.98px) {
      padding: 0;
    }
    @media screen and (max-width: 1024.98px) {
      flex: 0 0 auto !important;
      width: 100% !important;
    }
  }
  .btn-close {
    @media screen and (max-width: 575.98px) {
      background-color: transparent;
      border: none;
      top: 1.5rem;
      right: 1rem;

      svg {
        height: 0.6rem;
        width: 0.6rem;
      }
    }
  }
}

#login_modal.modal.auth-modal {
  @media (max-width: 1024.98px) and (max-height: 650px) {
    .modal-dialog-centered {
      height: unset !important;
      max-height: none !important;
    }
    &.modal-dialog-centered {
      height: unset !important;
      max-height: none !important;
    }
    .modal-content {
      max-height: none !important;
    }
  }
  .modal-content {
    max-height: 600px;
  }
  .modal-body {
    // overflow-x: hidden;
    overflow: hidden;
  }
}

#login_modal.modal.user-profile-modal {
  .modal-content {
    overflow: hidden;
  }
}

.auth-form-wrap {
  padding: 12px 10px 0 24px;

  @media screen and (max-width: 991.98px) {
    padding: 24px;
  }

  @media screen and (max-width: 575.98px) {
    padding: 0;
  }

  .auth-logo {
    text-align: center;

    svg {
      margin: 8px auto 32px;
      // margin: 0 0 8px auto;
      width: 238px;
      height: 48px;

      @media screen and (max-width: 575.98px) {
        width: 178px;
        height: 36px;
        margin: 4px auto 16px;
      }
    }

    &--betaMobile {
      svg {
        width: 100%;
        height: 97px;
      }
    }
  }

  .auth-top-btn {
    .nav {
      gap: 0.75rem;

      .nav-item {
        flex: 1;

        .nav-link {
          background-color: var(--darkGray);
          border: 2px solid transparent;
          border-radius: 0.625rem;
          color: var(--gray-20);
          font-size: 0.875rem;
          font-weight: 500;
          max-height: 40px;
          line-height: 1.75rem;
          padding: 0.3125rem 1.625rem 0.25rem;
          width: 100%;
          @media screen and (max-width: 575.98px) {
            line-height: 1.5rem;
          }

          &.active,
          &:hover,
          &.active:hover {
            background-color: var(--gray-60);
            border: 2px solid transparent;
            color: var(--white);
          }
        }
      }
    }
  }

  .auth-btn-wrap {
    // margin: 0.5rem 0 0;
    margin: 0;

    .btn {
      height: 44px;
    }

    &--betaFilled {
      margin-bottom: 2rem;
    }
  }

  .auth-social-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      svg {
        width: 5rem;
      }
    }

    @media screen and (max-width: 767.98px) {
      width: 80px;

      & img {
        width: 100%;
      }
    }
  }

  form {
    .error-container {
      margin-top: 10px;
      height: 21px;

      p {
        margin: 0;
        font-size: 12px;
        @media screen and (max-width: 767.98px) {
          font-size: 12px;
        }
      }
    }
  }
}

.register-wrap {
  .forgot-password {
    color: var(--gray-40);
    font-weight: 500;
    font-size: 0.75rem !important;
  }
}

.twofa-wrap {
  p {
    color: var(--gray-40);
    font-weight: 400;
    font-size: 0.875rem !important;
  }
}
