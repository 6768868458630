.auth-img {
  height: 100%;
  overflow: hidden;
  border-radius: 0px 15px 15px 0px;
  position: relative;
}

.auth-img img {
  position: absolute;
  width: 100%;
  bottom: 0;
  // top: 0;
  object-fit: contain;
}

.auth-mob-instructions {
  padding: 1rem 0 0;
  @media screen and (max-width: 575.98px) {
    &.pb-5 {
      padding-bottom: 0 !important;
    }
    &.pb-add {
      padding-bottom: 1rem !important;
    }
  }
  p {
    font-size: 0.75rem;
    color: var(--white);
    font-weight: 600;
    line-height: 1rem;
    margin: 0;
    text-align: center;

    a {
      color: var(--blue-300);
      padding-left: 0.2rem;
    }
  }
}

.auth-instruction-text {
  position: absolute;
  width: 100%;
  bottom: 1rem;
  text-align: center;
  padding: 0.4rem 1rem !important;
  background-color: var(--darkGray-80);

  p {
    font-size: 0.875rem;
    color: var(--white);
    font-weight: 600;
    line-height: 20px;
    margin: 0;

    a {
      color: var(--blue-300);
      padding-left: 0.2rem;
    }
  }
}
