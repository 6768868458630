.custom-tabs-nav {
  padding-bottom: 1rem;

  .nav-scroll-arrow-wrapper {
    background: linear-gradient(270deg, #162331 0%, rgba(22, 35, 49, 0) 100%);
  }

  .nav {
    gap: 0.5rem;

    .nav-item {
      .nav-link {
        padding: 0.625rem 1rem !important;
        height: 2.75rem;
        color: var(--gray-20) !important;
        font-size: 1rem !important;
        font-weight: 600 !important;
        line-height: 1.5rem !important;
        background-color: var(--gray-85) !important;
        border: 2px solid var(--gray-85) !important;
        border-radius: 0.625rem !important;

        &:hover {
          background-color: var(--gray-60) !important;
          border: 2px solid var(--gray-60) !important;
          color: var(--white) !important;
        }

        &.active,
        &.active:hover {
          background-color: var(--gray-60) !important;
          border: 2px solid var(--gray-60) !important;
          color: var(--white) !important;
        }
      }
    }
  }
}
