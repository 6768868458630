// .content-wrapper{
//   margin: 3.75rem auto 0;
//   width: 100%;
//   padding: 2rem 0.625rem 0.625rem;
//   @media screen and (max-width: 767.98px){
//     padding: 1.5rem 0.938rem 0;
//   }
// }

@media screen and (max-width: 490px) {
  .urm__actionButton {
    left: -90px !important;
  }
}
