.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  color: var(--chatText);
  // &.show {
  //   padding-left: 200px;
  // }
  // &.hide {
  //   padding-left: 0px;
  // }
  .email-verification-container,
  .back-wrapper {
    width: 370px;
    padding: 32px 40px;
    border-radius: 8px;
    background-color: var(--gray-90);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .logo {
      width: 200px;
      // padding-bottom: 32px;
      // border-bottom: solid 1px var(--gray-60);
      height: auto !important;
      svg {
        height: 48px !important;
        width: 100%;
      }
    }
    .btn {
      line-height: 43px;
    }
    .sep {
      background-color: var(--gray-60);
      height: 1px;
      width: 100%;
      margin: 20px 0;
    }
    h1 {
      font-weight: bold;
      font-size: 48px;
      text-transform: uppercase;
      text-align: center;
      font-family: 'Tungsten', serif;
    }
    p {
      text-align: center;
      color: var(--gray-40);
      font-size: 18px;
      font-family: 'Rubik', sans-serif !important;
    }
  }
  .text-center {
    color: #fff;
    .fa-exclamation-triangle {
      margin-right: 16px;
    }
  }
  .notFound {
    color: #fff;
    margin: 34px;
  }
}
