.PhoneInput {
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  flex: 1;
  background-color: var(--gray-90);
  border: 1px solid var(--gray-90);
  color: var(--white);
  padding: 0.438rem 0.75rem;
  border-radius: 0 0.625rem 0.625rem 0;
  height: 3rem;
  font-size: 1rem;
  &:focus {
    color: var(--white);
    border: 1px solid var(--blue-300);
    background-color: var(--gray-90);
    outline: none;
    box-shadow: none;
  }
}

.PhoneInputCountryIcon {
  width: 32px;
  height: 32px;
}

.PhoneInputCountryIcon--square {
  width: 32px;
}

.PhoneInputCountryIcon--border {
  background-color: var(--gray-90);
  box-shadow: inset 0 0 0 0;
}

.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: 0.8;
  fill: var(--white);
}

.PhoneInputInternationalIconGlobe {
  opacity: 0.8;
  stroke: var(--white);
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  background-color: var(--gray-90);
  border: 1px solid var(--gray-90);
  height: 48px;
  padding: 7px 14px;
  border-radius: 10px 0 0 10px;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
  height: 48px !important;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 0.3em;
  height: 0.3em;
  margin-left: 0.35em;
  border-style: solid;
  border-color: inherit;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  transform: rotate(45deg);
  opacity: '0.45';
  border-color: var(--white);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
}

.inputForm {
  .text-danger {
    bottom: -6px;
    position: absolute;
    right: 0;
  }
}
// DARK MODE
.dark {
  .PhoneInputInput {
    background-color: var(--darkGray);
    border: 1px solid var(--darkGray);
    color: var(--white);
    &:focus {
      color: var(--white);
      border: 1px solid var(--blue-300);
      background-color: var(--darkGray);
    }
  }

  .PhoneInputCountryIcon--border {
    background-color: var(--darkGray);
    box-shadow: inset 0 0 0 0;
  }

  .PhoneInputCountry {
    background-color: var(--darkGray);
    border: 1px solid var(--darkGray);
  }

  .PhoneInputCountrySelectArrow {
    border-color: var(--white);
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }
}
