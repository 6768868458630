@import "../../../../assets/sass/env";

.acknowledgement-dialog {
  @media screen and (min-width: 992px) {
    min-width: 660px;
  }

  .acknowledgement {
    background-image: url('#{$s3Path}/assets/Icons/png/acknowledgement-icon.webp');

    .acknowledgement-body {
      padding: 2.5rem !important;

      @media screen and (max-width: 375px) {
        padding: 1.5rem !important;
      }

      .ack-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        max-width: 351px;

        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }

      .ack-btn {
        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }
}
