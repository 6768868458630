.right-section-wrap.sidebar-close {
  padding-left: 66px;
}

.header-toggle-icon {
  margin: 8px 4px;
  width: 44px;
  cursor: pointer;

  @media screen and (max-width: 767.98px) {
    margin-left: 0.5em;
  }
}

.header-toggle-icon {
  &:hover {
    svg {
      fill: var(--gray-60);
    }
  }
}

.sidebar-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-wrap {
  ::-webkit-scrollbar {
    display: none;
  }

  &.sidebar-close {
    .sidebar-balance-wrap {
      display: none !important;
    }

    .sidevar-menu-heading {
      visibility: hidden;

      &:first-child {
        display: none;
      }
    }

    .sidebar-menu-list {
      padding: 0 0.75rem;

      p,
      .menu-arrow {
        display: none;
      }

      li {
        a {
          padding: 0.625rem 0.313rem;

          @media screen and (max-width: 1024.98px) {
            padding: 0.65rem;
          }

          &.active,
          &:hover {
            background: transparent;
            border-color: transparent;

            &:before {
              opacity: 0;
            }
          }
        }
      }
    }

    .sidebar-bottom-title {
      display: none;
    }

    .after-login-sidebar-content {
      @media screen and (max-width: 1400px) {
        display: none !important;
      }
    }
  }
}

.sidebar-reverse {
  @media screen and (max-width: 1199.98px) {
    display: flex;
    flex-direction: column-reverse;
    .sidebar-balance-wrap {
      padding: 1rem;
    }
    .header-toggle-icon {
      display: none;
    }
  }
  @media screen and (max-width: 768.98px) {
    .sidebar-balance-wrap {
      width: 100% !important;
    }
  }
}

.sidebar-content-wrap {
  max-height: calc(100vh - 5.25rem);
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 775px) {
    max-height: 100%;
  }

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 1.75rem;
  }

  // .rc-tooltip-content {
  //   background: var(----gray-80);
  //   border-radius: 0.313rem;
  // }
  .mob-menu-header {
    background: var(--gray-85);
    border-bottom: 1px solid var(--gray-80);
    padding: 1.25rem;
    display: none !important;
    position: relative;

    @media screen and (max-width: 767px) {
      display: flex !important;
    }

    h3 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.125rem;
      color: var(--white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;

      path {
        fill: var(--gray-40);
      }
    }
  }
}

.sidebar-balance-wrap {
  padding: 16px 5px;

  .align-balance {
    max-width: 195px;
  }

  h4 {
    color: var(--white);
    font-weight: 600;
    font-size: 1rem;

    span {
      color: var(--gray-40);
    }
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray-40);
    display: flex;
    align-items: center;
    margin: 0;

    span {
      padding-left: 0.313rem;

      &.text-red {
        color: var(--red);
      }

      &.text-green {
        color: var(--green);
      }
    }
  }

  .currency-icon {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-right: 0.625rem;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .search-games {
    input {
      padding: 12px 12px 12px 40px;
    }

    svg {
      top: 12px;
      left: 10px;
    }
  }
}

.sidebar-wrap {
  font-weight: 800;
  width: 250px;
  position: fixed;
  top: 75px;
  height: 100vh;

  @media screen and (max-width: 767px) {
    top: 76px;
    z-index: 1000 !important;
    width: 100% !important;
  }

  @media screen and (max-width: 575.98px) {
    top: 64px;
  }
}

.sidebar-wrap.sidebar-close .sidebar-top-cta {
  display: none;
}

.sidebar-wrap.sidebar-close .sidebar-listing-wrap li a {
  padding: 10px 0;
  justify-content: center;
}

.sidebar-wrap.sidebar-close .sidebar-listing-wrap li {
  padding: 10px 0;
}

.sidebar-wrap.sidebar-close {
  width: 0 !important;

  @media screen and (min-width: 1199px) {
    /* transform: translate(-180px); */
    width: 52px !important;
  }
}

.sidebar-wrap.sidebar-close .favourite-btn a {
  padding-left: 10px;
}

.sidebar-wrap {
  &.sidebar-close {
    .favourite-btn {
      margin: 30px 6px 20px;
    }
  }
}

.favourite-btn a span {
  padding-left: 16px;
}

.sidebar-wrap.sidebar-close .language-select-wrap select,
.sidebar-wrap.sidebar-close .theme-switcher-wrap .toggle-slot {
  background: transparent;
  border: transparent;
}

.sidebar-wrap.sidebar-close .language-select-wrap img {
  left: -3px;
}

.sidebar-wrap.sidebar-close .sidebar-listing-wrap li a span,
.sidebar-wrap.sidebar-close .favourite-btn a span,
.sidebar-wrap.sidebar-close .language-wrap span,
.sidebar-wrap.sidebar-close .theme-label,
.sidebar-wrap.sidebar-close .theme-switcher-wrap .toggle-slot:after,
.sidebar-wrap.sidebar-close .sidebar-ad-section {
  display: none;
}

.sidebar-wrap.sidebar-close .sidebar-listing-wrap li:hover a,
.sidebar-wrap.sidebar-close .sidebar-listing-wrap li.active a {
  background: transparent;
}

.sidebar-wrap .sports-btn {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: var(--cinder);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sports-listing {
  padding: 20px;
}

.sidebar-wrap .sports-btn img:nth-child(2),
.sidebar-wrap .sports-btn:hover img:nth-child(1),
.sidebar-wrap .sports-btn.active img:nth-child(1) {
  display: none;
}

.sidebar-wrap .sports-btn:hover img:nth-child(2),
.sidebar-wrap .sports-btn.active img:nth-child(2) {
  display: block;
}

.sidebar-wrap .sports-btn:hover,
.sidebar-wrap .sports-btn.active {
  background: var(--aquaBlue);
}

.sidebar-listing-wrap {
  padding: 20px 5px 0;
}

.sidebar-listing-wrap li {
  margin-bottom: 5px;
}

.sidebar-listing-wrap li a,
.favourite-btn a {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
  color: var(--white);
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
}

.favourite-btn a {
  padding: 12px 5px;
}

.sidebar-listing-wrap li a span {
  padding-left: 35px;
  white-space: nowrap;
}

.sidebar-listing-wrap li a img {
  position: absolute;
}

.sidebar-listing-wrap .sidebar-submenu li img {
  width: 18px;
  height: 18px;
}

.sidebar-listing-wrap li:hover > a,
.sidebar-listing-wrap li.active > a {
  background: var(--lightSlate);
  color: var(--white);
}

.sidebar-listing-wrap li:hover a svg {
  fill: var(--aquaBlue);
}

.sidebar-listing-wrap li a img:nth-child(2) {
  display: none;
}

.sidebar-listing-wrap li:hover > a img:nth-child(2),
.sidebar-listing-wrap li.active > a img:nth-child(2) {
  display: block;
  filter: brightness(0) saturate(100%) invert(32%) sepia(96%) saturate(1267%)
    hue-rotate(173deg) brightness(95%) contrast(98%);
}

.sidebar-wrap .accordion-body {
  padding: 10px;
}

.sidebar-wrap.sidebar-close .sidebar-toggle-btn img {
  transform: rotate(180deg);
}

.sidebar-wrap.sidebar-close .sidebar-listing-wrap {
  padding-top: 50px;
}

.sidebar-wrap {
  z-index: 10;
  // box-shadow: 1px 0 1px #0b1216;
  border-right: 1px solid var(--gray-80);
  background-color: var(--gray-90);
  display: flex;
  transition: all 0.3s ease-in-out;

  .sidebar-toggle-btn {
    position: absolute;
    left: auto;
    top: 76px;
    right: -10px;
    z-index: 3;

    @media screen and (max-width: 1024.98px) {
      right: 0;
      top: 6px;
    }
  }

  &.sidebar-close {
    .sidebar-toggle-btn {
      right: 0;
      left: -30px;
      padding: 0;

      @media screen and (max-width: 767.98px) {
        left: -40px;
      }

      @media screen and (min-width: 1025px) {
        left: 10px;
        top: 20px;
        transform: rotate(180deg);
      }
    }

    .sidebar-toggle-btn img {
      transform: rotate(180deg);
    }

    .sidebar-listing-wrap {
      padding-top: 50px;
    }
  }

  .sidebar-menu-list {
    margin: 0.25rem 0 0.625rem 0;
    padding: 0 1rem 1rem 1rem;

    @media screen and (max-width: 775px) {
      max-height: 70dvh;
      overflow-y: auto;
      padding-bottom: 110px;
    }

    h4 {
      color: var(--gray-40);
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    li {
      margin-bottom: 0.1rem;

      & > a {
        padding: 0.68rem;
        color: var(--white);
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        border-radius: 0.5rem;
        transition: all 0.2s ease 0s;
        position: relative;
        min-width: 208px;

        svg,
        path {
          transition: all 0.2s ease 0s;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
          opacity: 0;
          background: var(--sidebarBtnGradient);
          z-index: -1;
          transition: opacity 0.2s ease 0s;
        }

        &.active {
          p {
            color: var(--white);
          }
        }

        p {
          font-weight: 500;
          max-width: 130px;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1rem;
        }

        &:hover,
        &.active {
          color: var(--white);

          &:before {
            opacity: 1;
          }

          .menu-arrow {
            background: var(--white-015);

            i {
              color: var(--white);
            }

            &-transparent {
              background: transparent;
            }
          }

          & img {
            filter: brightness(10);
          }

          .menu-icon {
            & svg {
              path {
                fill: var(--white);
              }
            }
          }

          & img.crypto-img {
            filter: none;
          }
        }

        &.crypto-list {
          & svg {
            filter: unset;
          }
        }
      }

      .menu-arrow {
        padding: 0.02rem 0.325rem;
        border-radius: 0.375rem;
        text-align: center;
        background: var(--gray-80);
        color: var(--gray-20);
        position: absolute;
        right: 0.5rem;
        width: 20px;
        height: 20px;
        transition: background-color 0.2s ease 0s;

        i {
          color: var(--gray-20);
          transition: color 0.2s ease 0s;
        }
      }

      .loading-option {
        padding: unset;
        background: none;
        animation: rotate 1.5s linear infinite;
      }
    }

    .menu-icon {
      margin-right: 0.625rem;

      svg {
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    .menu-search-icon {
      path {
        fill: var(--gray-40);
      }
    }

    .crypto-cont a {
      padding: 5px;
    }

    .submenu-wrap {
      border-radius: 0;
      padding: 0;
      animation: 400ms ease-in-out 0s 1 normal none running accordianEffect;

      li > a {
        // justify-content: flex-start;
        color: var(--gray-20);

        &:before {
          display: none !important;
        }

        & img {
          margin-right: 0.625rem;
          width: 1.125rem;
        }

        svg,
        path {
        }

        & svg {
          margin-right: 0.313rem;
        }

        &:hover,
        &.active {
          color: var(--white);
          background: transparent;
          border-color: transparent;
        }
      }

      .crypto-list-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .crypto-list-right {
          position: relative;

          svg,
          img {
            margin-right: 0;
            margin-left: 0;
            position: absolute;
            right: -0.625rem;
            top: 0;
            width: 1rem;
            height: 1rem;
            filter: unset;
          }

          span {
            margin-right: 0.313rem;
            color: var(--white);
            font-size: 0.75rem;
            font-weight: 500;
          }
        }

        .crypto-list {
          width: 100%;
          min-width: auto;

          .crypto-list-left {
            svg,
            img {
              height: 1.5rem;
              width: 1.5rem;
              border-radius: 100%;
            }
          }

          &:hover {
            svg {
              filter: unset;
            }
          }
        }
      }

      .crypto-list {
        .crypto-list-right {
          position: relative;

          svg,
          img {
            margin-left: 0;
            position: absolute;
            right: -0.625rem;
            top: 0;
            width: 1rem;
            height: 1rem;
            margin-right: 0.313rem;
            filter: unset;
          }

          span {
            margin-right: 10px;
            color: var(--white);
            font-size: 0.75rem;
          }
        }

        .crypto-list-left {
          svg,
          img {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 100%;
          }
        }

        &:hover {
          svg {
            filter: unset;
          }
        }
      }
    }
  }
}

.sidebar-top-cta {
  background: var(--primaryDark);
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0px 4px 4px var(--blackOpacity50);
  width: 100%;
  min-height: 48px;
  align-items: center;

  .nav-link {
    img {
      max-width: 25px;
      padding-left: 0 !important;
    }
  }
}

.nav-link.cta-btn {
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  color: var(--white);
  width: 100%;
  white-space: nowrap;
}

.sidebar-top-cta-wrap {
  padding: 10px 5px 0;
}

.advertisement-card {
  height: 180px;
  border-radius: 18px;
  overflow: hidden;
}

.advertisement-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// mobile bottom bar css start
.Mobile-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4.25rem;
  background-color: var(--gray-85);
  border-top: 1px solid var(--gray-80);
  z-index: 1199;
  box-shadow: 0px 0px 4px var(--blackOpacity50);

  .list-inline {
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);

    .list-inline-item {
      flex: 1 0 0%;
      margin-right: 0 !important;
      padding: 0.75rem 0.25rem;
      text-align: center;

      span {
        font-weight: 400;
        font-size: 0.625rem;
        color: var(--gray-20);
        white-space: nowrap;
      }

      &.active {
        img {
          filter: brightness(0) saturate(100%) invert(21%) sepia(74%)
            saturate(2846%) hue-rotate(177deg) brightness(94%) contrast(99%);
        }

        span {
          color: var(--aquaBlue);
        }
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .list-inline {
      padding-left: 100px;
    }
  }

  & button {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 !important;
    border: none;
    padding: 0.25rem;
    margin: 0 auto;

    & svg {
      width: 1.75rem;
      height: 1.75rem;

      path {
        fill: var(--gray-20);
      }
    }

    i {
      color: var(--gray-20);
    }

    &:active,
    &:hover,
    &:focus {
      background: transparent !important;
      border: none !important;
    }

    &.active {
      background: transparent !important;
      border: none !important;

      svg {
        path {
          fill: var(--white);
        }
      }

      i {
        color: var(--white);
      }
    }
  }

  // &:before {
  //   content: '';
  //   background-image: url(../../ui-kit/Icons/svg/mobile-bottom.svg);
  //   background-repeat: no-repeat;
  //   position: absolute;
  //   height: 25px;
  //   width: 101px;
  //   bottom: 48.5px;
  //   left: 50%;
  //   z-index: -1;
  //   transform: translate(-50%, -50%);
  // }

  .profile-mob button {
    background: var(--secondaryRadialBtn);
    border-radius: 30px;
    padding: 0 !important;
    flex: none !important;
    height: 55px;
    width: 55px;
    box-shadow: 0px 14px 32px 0px #00a4fa4d !important;
    position: absolute;
    top: -10px;
    transform: translateX(50%);
    right: 50%;

    &:hover {
      background: var(--secondaryRadialBtn) !important;
    }

    svg {
      filter: brightness(10);
    }
  }
}
